import { useWeb3Context } from 'src/context/web3';
import { INftInventory } from 'src/interface';

import useXellarReducer from '../reducers/useXellarReducer';

export interface ContractOwnedNfts {
	address: string;
	abi: string;
	uri_format: string;
}

interface INftObject {
	nftId: string;
	tokenId: string;
	uri: string;
}

export interface IGetAirdropAmount {
	type: string;
	itemAbi: string;
	itemAddress: string;
}

export interface ITransferNft {
	contractInfo: INftInventory['contract_info'];
	recipientAddress: string;
	tokenId: number;
}

const useWeb3Inventory = () => {
	const { canAccessWeb3, isXellar, getXellarContract, getContract, address } = useWeb3Context();

	const { xellarSendTransaction, setXellarItemData } = useXellarReducer();

	const web3GetOwnedNfts = async (contract: ContractOwnedNfts) => {
		const list: Array<INftObject> = [];

		if (canAccessWeb3) {
			try {
				if (isXellar) {
					const nftContract = await getXellarContract(contract.abi, contract.address);

					const balance = await nftContract.balanceOf(address);

					for (let i = 0; i < balance; i++) {
						const tokenId = await nftContract.tokenOfOwnerByIndex(address, i);

						const uri = await nftContract.tokenURI(tokenId);

						if (uri.toString().includes(contract.uri_format)) {
							const nftId = uri.toString().replace(contract.uri_format, '');
							list.push({
								nftId,
								tokenId,
								uri,
							});
						}
					}
				} else {
					const nftContract = await getContract(contract.abi, contract.address);
					const balance = await nftContract.methods.balanceOf(address).call();

					for (let i = 0; i < balance; i++) {
						const tokenId = await nftContract.methods.tokenOfOwnerByIndex(address, i).call();

						const uri = await nftContract.methods.tokenURI(tokenId).call();

						if (uri.toString().includes(contract.uri_format)) {
							const nftId = uri.toString().replace(contract.uri_format, '');
							list.push({
								nftId,
								tokenId,
								uri,
							});
						}
					}
				}
			} catch (_err) {
				console.error(_err);
				return list;
			}
		}

		return list;
	};

	const web3GetAirdropAmount = async (props: IGetAirdropAmount) => {
		const { type, itemAbi, itemAddress } = props;
		if (canAccessWeb3) {
			try {
				if (isXellar) {
					const itemContract = await getXellarContract(itemAbi, itemAddress);
					const res = await itemContract.airdrops(type, address);
					return res;
				} else {
					const itemContract = await getContract(itemAbi, itemAddress);
					const res = await itemContract.methods.airdrops(type, address).call();
					return res;
				}
			} catch (_err) {
				return false;
			}
		}

		return false;
	};

	const web3ClaimAirdrop = async (props: IGetAirdropAmount) => {
		const { type, itemAbi, itemAddress } = props;

		if (canAccessWeb3) {
			try {
				if (isXellar) {
					setXellarItemData({ funcName: 'CLAIM AIRDROP' });
					const res: any = await xellarSendTransaction(
						itemAddress,
						itemAbi,
						address,
						'claimAirdrop',
						type,
					);

					if (res?.status === false) {
						return false;
					}

					return true;
				} else {
					const contract = await getContract(itemAbi, itemAddress);

					const res = await contract.methods.claimAirdrop(type).send({ from: address });

					return res;
				}
			} catch (error) {
				console.error(error);
				return false;
			}
		}

		return false;
	};

	const web3TransferNft = async (props: ITransferNft) => {
		const errObj = {
			status: false,
			data: undefined,
		};

		const { contractInfo, tokenId, recipientAddress } = props;
		if (canAccessWeb3) {
			try {
				if (isXellar) {
					setXellarItemData({ funcName: 'TRANSFER NFT' });
					const transfer: any = await xellarSendTransaction(
						contractInfo.contract_address,
						contractInfo.contract_abi,
						address,
						'transferFrom',
						address,
						recipientAddress,
						tokenId,
					);

					return transfer;
				} else {
					const contract = await getContract(
						contractInfo.contract_abi,
						contractInfo.contract_address,
					);

					// const approval = await contract.methods
					// .approve(contractInfo.contract_address, tokenId)
					// .send({ from: address });

					// if (!approval) {
					// return {
					// ...errObj,
					// message: 'User rejected',
					// data: undefined,
					// };
					// }

					const transfer = await contract.methods
						.transferFrom(address, recipientAddress, tokenId)
						.send({ from: address });

					return transfer;
				}
			} catch (error: any) {
				console.error(error);
				return { ...errObj, message: error?.message };
			}
		}
	};

	const web3GetNftOwner = async (contractAbi: any, contractAddress: any, tokenId: any) => {
		if (!canAccessWeb3) {
			return false;
		}

		try {
			if (isXellar) {
				const nftContract = await getXellarContract(contractAbi, contractAddress);
				const owner = await nftContract.ownerOf(tokenId);

				return owner;
			} else {
				const nftContract = await getContract(contractAbi, contractAddress);
				const owner = await nftContract.methods.ownerOf(tokenId).call();

				return owner;
			}
		} catch (_err) {
			return false;
		}
	};

	return {
		web3GetOwnedNfts,
		web3GetAirdropAmount,
		web3ClaimAirdrop,
		web3TransferNft,
		web3GetNftOwner,
	};
};

export default useWeb3Inventory;
