const storeabi = [
	{
		inputs: [
			{
				internalType: 'string',
				name: 'productId',
				type: 'string',
			},
			{
				internalType: 'string',
				name: 'name',
				type: 'string',
			},
			{
				internalType: 'uint256',
				name: 'price',
				type: 'uint256',
			},
			{
				internalType: 'address',
				name: 'tokenAddress',
				type: 'address',
			},
			{
				internalType: 'uint256',
				name: 'discount',
				type: 'uint256',
			},
		],
		name: 'addProduct',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'string',
				name: 'productId',
				type: 'string',
			},
		],
		name: 'buyProduct',
		outputs: [],
		stateMutability: 'payable',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'string',
				name: 'productId',
				type: 'string',
			},
			{
				internalType: 'address',
				name: 'buyer',
				type: 'address',
			},
			{
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256',
			},
			{
				internalType: 'address',
				name: 'tokenAddress',
				type: 'address',
			},
		],
		name: 'cancelTransaction',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_pancakseSwapRouter',
				type: 'address',
			},
			{
				internalType: 'address',
				name: '_globalToken',
				type: 'address',
			},
			{
				internalType: 'uint256',
				name: '_fee',
				type: 'uint256',
			},
			{
				internalType: 'address',
				name: '_feeRecipient',
				type: 'address',
			},
			{
				internalType: 'address',
				name: '_recipient',
				type: 'address',
			},
		],
		stateMutability: 'nonpayable',
		type: 'constructor',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'actor',
				type: 'address',
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'buyer',
				type: 'address',
			},
			{
				indexed: false,
				internalType: 'string',
				name: 'productId',
				type: 'string',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'address',
				name: 'tokenAddress',
				type: 'address',
			},
		],
		name: 'Cancel',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'actor',
				type: 'address',
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'seller',
				type: 'address',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'address',
				name: 'tokenAddress',
				type: 'address',
			},
		],
		name: 'Disburse',
		type: 'event',
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256',
			},
			{
				internalType: 'address',
				name: 'tokenAddress',
				type: 'address',
			},
		],
		name: 'disburseToken',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'buyer',
				type: 'address',
			},
			{
				indexed: false,
				internalType: 'string',
				name: 'productId',
				type: 'string',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'address',
				name: 'tokenAddress',
				type: 'address',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'feePct',
				type: 'uint256',
			},
		],
		name: 'Purchase',
		type: 'event',
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'user',
				type: 'address',
			},
			{
				internalType: 'bool',
				name: 'isAdminBool',
				type: 'bool',
			},
		],
		name: 'setAdmin',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: '_fee',
				type: 'uint256',
			},
		],
		name: 'setFee',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_feeRecipient',
				type: 'address',
			},
		],
		name: 'setFeeRecipientAddress',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'string',
				name: 'productId',
				type: 'string',
			},
			{
				internalType: 'uint256',
				name: 'price',
				type: 'uint256',
			},
			{
				internalType: 'uint256',
				name: 'discount',
				type: 'uint256',
			},
		],
		name: 'setProductDetail',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'string',
				name: 'productId',
				type: 'string',
			},
			{
				internalType: 'uint256',
				name: 'status',
				type: 'uint256',
			},
		],
		name: 'setProductStatus',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'actor',
				type: 'address',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'address',
				name: 'tokenAddress',
				type: 'address',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'feeAmount',
				type: 'uint256',
			},
		],
		name: 'Settle',
		type: 'event',
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256',
			},
			{
				internalType: 'address',
				name: 'tokenAddress',
				type: 'address',
			},
		],
		name: 'settleTransaction',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address',
			},
		],
		name: 'balances',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'tokenAddress',
				type: 'address',
			},
		],
		name: 'checkDisburseableBalance',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address',
			},
		],
		name: 'disbursedAmount',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'fee',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_token1',
				type: 'address',
			},
			{
				internalType: 'address',
				name: '_token2',
				type: 'address',
			},
			{
				internalType: 'uint256',
				name: '_amount',
				type: 'uint256',
			},
		],
		name: 'getExchangeRate',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'string',
				name: 'productId',
				type: 'string',
			},
		],
		name: 'getProductByProductId',
		outputs: [
			{
				components: [
					{
						internalType: 'string',
						name: 'productId',
						type: 'string',
					},
					{
						internalType: 'string',
						name: 'name',
						type: 'string',
					},
					{
						internalType: 'contract IERC20',
						name: 'token',
						type: 'address',
					},
					{
						internalType: 'uint256',
						name: 'price',
						type: 'uint256',
					},
					{
						internalType: 'uint256',
						name: 'priceInToken',
						type: 'uint256',
					},
					{
						internalType: 'uint256',
						name: 'discount',
						type: 'uint256',
					},
					{
						internalType: 'uint256',
						name: 'sold',
						type: 'uint256',
					},
					{
						internalType: 'uint256',
						name: 'cancelled',
						type: 'uint256',
					},
					{
						internalType: 'uint256',
						name: 'status',
						type: 'uint256',
					},
				],
				internalType: 'struct BEP20Store.Product',
				name: '',
				type: 'tuple',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'getProducts',
		outputs: [
			{
				components: [
					{
						internalType: 'string',
						name: 'productId',
						type: 'string',
					},
					{
						internalType: 'string',
						name: 'name',
						type: 'string',
					},
					{
						internalType: 'contract IERC20',
						name: 'token',
						type: 'address',
					},
					{
						internalType: 'uint256',
						name: 'price',
						type: 'uint256',
					},
					{
						internalType: 'uint256',
						name: 'priceInToken',
						type: 'uint256',
					},
					{
						internalType: 'uint256',
						name: 'discount',
						type: 'uint256',
					},
					{
						internalType: 'uint256',
						name: 'sold',
						type: 'uint256',
					},
					{
						internalType: 'uint256',
						name: 'cancelled',
						type: 'uint256',
					},
					{
						internalType: 'uint256',
						name: 'status',
						type: 'uint256',
					},
				],
				internalType: 'struct BEP20Store.Product[]',
				name: '',
				type: 'tuple[]',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'globalToken',
		outputs: [
			{
				internalType: 'contract IERC20',
				name: '',
				type: 'address',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'user',
				type: 'address',
			},
		],
		name: 'isAdmin',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'owner',
		outputs: [
			{
				internalType: 'address payable',
				name: '',
				type: 'address',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address',
			},
		],
		name: 'settledAmount',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
];

export default storeabi;
