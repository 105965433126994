import BigNumber from 'bignumber.js';

const convertBigNumber = (number: string | number, fixed = 0) => {
	// Create a BigNumber instance with the given value
	const value = new BigNumber(number);

	// Perform the division by 10^18
	const result = value.dividedBy(new BigNumber('1e18'));

	// Convert the result to a number (note: this might lead to loss of precision for very large values)
	const numberResult = result.toNumber();

	return parseFloat(numberResult.toFixed(fixed));
};

export default convertBigNumber;
