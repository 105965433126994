import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Web3State = {
	creoBalance: number | null | undefined;
	tokenBalance: number | null | undefined;
	nftBalance: any | null | undefined;
};

export type Web3Action = {
	['SET_BALANCE']: {
		creoBalance?: Web3State['creoBalance'];
		tokenBalance?: Web3State['tokenBalance'];
	};
	['SET_NFT']: {
		nftBalance?: Web3State['nftBalance'];
	};
};

export const web3InitialState: Web3State = {
	creoBalance: null,
	tokenBalance: null,
	nftBalance: null,
};

export const web3Slice = createSlice({
	name: 'web3slice',
	initialState: web3InitialState,
	reducers: {
		setWeb3Balance(state: any, { payload }: PayloadAction<Web3Action['SET_BALANCE']>) {
			Object.assign(state, payload);
		},
		setWeb3Nft(state: any, { payload }: PayloadAction<Web3Action['SET_NFT']>) {
			Object.assign(state, payload);
		},
		resetWeb3State(state: any) {
			Object.assign(state, web3InitialState);
		},
	},
});

// Action creators are generated for each case reducer function
export const { setWeb3Balance, setWeb3Nft, resetWeb3State } = web3Slice.actions;

export default web3Slice;
