import { ModalProps } from 'antd/lib/modal';
import Modal from 'antd/lib/modal/Modal';
import classNames from 'classnames';

import ImageC from '../Image';
import Spinner from '../Spinner';

import classes from './index.module.less';
export interface ModalCProps extends ModalProps {
	className?: string;
	classNamesMask?: string;
	closeOnOverlayClick?: boolean;
	showCloseIcon?: boolean;
	loading?: boolean;
}

const ModalC = ({
	className = '',
	classNamesMask,
	children,
	open,
	onClose,
	closeOnOverlayClick,
	showCloseIcon = true,
	loading = false,
	...props
}: ModalCProps) => {
	return (
		<Modal
			className={classes.modal}
			classNames={{
				mask: classNames(classes['modal-mask'], classNamesMask),
				wrapper: classes['modal-wrap'],
				content: classNames(classes['modal-content'], className),
				footer: classes['modal-footer'],
			}}
			centered
			open={open}
			onCancel={onClose}
			maskClosable={loading ? false : closeOnOverlayClick}
			closeIcon={
				showCloseIcon ? <ImageC src="/icons/icon-close.svg" height={35} width={35} /> : null
			}
			{...props}
		>
			{loading && (
				<div className={classes['loading-layer']}>
					<Spinner />
				</div>
			)}
			{children}
		</Modal>
	);
};

export default ModalC;
