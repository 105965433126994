import { useQuery } from 'react-query';

import web3config from 'src/utils/web3config';

import { useAPICaller } from './useAPICaller';

export const queryKeyCreoEngineData = 'queryKeyCreoEngineData';

export const useGetCreoEngineData = (enabled = true) => {
	const { fetchAPI } = useAPICaller();

	const { pancakeswapUrl } = web3config;

	return useQuery(
		[queryKeyCreoEngineData],
		() => {
			return fetchAPI({
				endpoint: pancakeswapUrl,
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false, enabled: !!enabled },
	);
};

export const useGetBnbData = (enabled = true) => {
	const { fetchAPI } = useAPICaller();

	return useQuery(
		['queryKeyBnbData'],
		() => {
			return fetchAPI({
				endpoint: 'https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd',
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false, enabled: !!enabled },
	);
};
