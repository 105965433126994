const bridge = {
	eth_mainnet: {
		chain_id: '1',
		assist: {
			address: '0x8351dE5788022A96047F34918a97a9F871ed6e8b',
			abi: [
				{
					inputs: [
						{
							internalType: 'contract IERC20',
							name: 'token',
							type: 'address',
						},
						{
							internalType: 'uint256',
							name: 'limitPerSend_',
							type: 'uint256',
						},
						{
							internalType: 'address',
							name: 'feeWallet_',
							type: 'address',
						},
						{
							internalType: 'uint256',
							name: 'feeSend_',
							type: 'uint256',
						},
						{
							internalType: 'uint256',
							name: 'feeFulfill_',
							type: 'uint256',
						},
						{
							internalType: 'address',
							name: 'owner',
							type: 'address',
						},
						{
							internalType: 'address[]',
							name: 'relayers_',
							type: 'address[]',
						},
						{
							internalType: 'uint256',
							name: 'relayerConsensusThreshold_',
							type: 'uint256',
						},
					],
					stateMutability: 'nonpayable',
					type: 'constructor',
				},
				{
					anonymous: false,
					inputs: [
						{
							indexed: true,
							internalType: 'string',
							name: 'fromUser',
							type: 'string',
						},
						{
							indexed: true,
							internalType: 'address',
							name: 'toUser',
							type: 'address',
						},
						{
							indexed: false,
							internalType: 'string',
							name: 'fromChain',
							type: 'string',
						},
						{
							indexed: false,
							internalType: 'string',
							name: 'toChain',
							type: 'string',
						},
						{
							indexed: false,
							internalType: 'uint256',
							name: 'amount',
							type: 'uint256',
						},
						{
							indexed: false,
							internalType: 'uint256',
							name: 'exchangeRate',
							type: 'uint256',
						},
					],
					name: 'FulfilledTokens',
					type: 'event',
				},
				{
					anonymous: false,
					inputs: [
						{
							indexed: false,
							internalType: 'address',
							name: 'account',
							type: 'address',
						},
					],
					name: 'Paused',
					type: 'event',
				},
				{
					anonymous: false,
					inputs: [
						{
							indexed: true,
							internalType: 'bytes32',
							name: 'role',
							type: 'bytes32',
						},
						{
							indexed: true,
							internalType: 'bytes32',
							name: 'previousAdminRole',
							type: 'bytes32',
						},
						{
							indexed: true,
							internalType: 'bytes32',
							name: 'newAdminRole',
							type: 'bytes32',
						},
					],
					name: 'RoleAdminChanged',
					type: 'event',
				},
				{
					anonymous: false,
					inputs: [
						{
							indexed: true,
							internalType: 'bytes32',
							name: 'role',
							type: 'bytes32',
						},
						{
							indexed: true,
							internalType: 'address',
							name: 'account',
							type: 'address',
						},
						{
							indexed: true,
							internalType: 'address',
							name: 'sender',
							type: 'address',
						},
					],
					name: 'RoleGranted',
					type: 'event',
				},
				{
					anonymous: false,
					inputs: [
						{
							indexed: true,
							internalType: 'bytes32',
							name: 'role',
							type: 'bytes32',
						},
						{
							indexed: true,
							internalType: 'address',
							name: 'account',
							type: 'address',
						},
						{
							indexed: true,
							internalType: 'address',
							name: 'sender',
							type: 'address',
						},
					],
					name: 'RoleRevoked',
					type: 'event',
				},
				{
					anonymous: false,
					inputs: [
						{
							indexed: false,
							internalType: 'address',
							name: 'fromUser',
							type: 'address',
						},
						{
							indexed: true,
							internalType: 'string',
							name: 'toUser',
							type: 'string',
						},
						{
							indexed: false,
							internalType: 'string',
							name: 'fromChain',
							type: 'string',
						},
						{
							indexed: false,
							internalType: 'string',
							name: 'toChain',
							type: 'string',
						},
						{
							indexed: false,
							internalType: 'uint256',
							name: 'amount',
							type: 'uint256',
						},
						{
							indexed: false,
							internalType: 'uint256',
							name: 'exchangeRate',
							type: 'uint256',
						},
					],
					name: 'SentTokens',
					type: 'event',
				},
				{
					anonymous: false,
					inputs: [
						{
							indexed: false,
							internalType: 'address',
							name: 'account',
							type: 'address',
						},
					],
					name: 'Unpaused',
					type: 'event',
				},
				{
					inputs: [],
					name: 'CURRENT_CHAIN',
					outputs: [
						{
							internalType: 'string',
							name: '',
							type: 'string',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'CURRENT_CHAIN_B32',
					outputs: [
						{
							internalType: 'bytes32',
							name: '',
							type: 'bytes32',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'DEFAULT_ADMIN_ROLE',
					outputs: [
						{
							internalType: 'bytes32',
							name: '',
							type: 'bytes32',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'FEE_DENOMINATOR',
					outputs: [
						{
							internalType: 'uint256',
							name: '',
							type: 'uint256',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'FULFILL_TX_TYPEHASH',
					outputs: [
						{
							internalType: 'bytes32',
							name: '',
							type: 'bytes32',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'MANAGER_ROLE',
					outputs: [
						{
							internalType: 'bytes32',
							name: '',
							type: 'bytes32',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'MAX_RELAYERS',
					outputs: [
						{
							internalType: 'uint256',
							name: '',
							type: 'uint256',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'TOKEN',
					outputs: [
						{
							internalType: 'contract IERC20',
							name: '',
							type: 'address',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'string[]',
							name: 'chains',
							type: 'string[]',
						},
						{
							internalType: 'uint256[]',
							name: 'exchangeRatesFromPow',
							type: 'uint256[]',
						},
					],
					name: 'addChains',
					outputs: [],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'bytes32',
							name: '',
							type: 'bytes32',
						},
					],
					name: 'exchangeRateFrom',
					outputs: [
						{
							internalType: 'uint256',
							name: '',
							type: 'uint256',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'feeFulfill',
					outputs: [
						{
							internalType: 'uint256',
							name: '',
							type: 'uint256',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'feeSend',
					outputs: [
						{
							internalType: 'uint256',
							name: '',
							type: 'uint256',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'feeWallet',
					outputs: [
						{
							internalType: 'address',
							name: '',
							type: 'address',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [
						{
							components: [
								{
									internalType: 'uint256',
									name: 'amount',
									type: 'uint256',
								},
								{
									internalType: 'string',
									name: 'fromUser',
									type: 'string',
								},
								{
									internalType: 'address',
									name: 'toUser',
									type: 'address',
								},
								{
									internalType: 'string',
									name: 'fromChain',
									type: 'string',
								},
								{
									internalType: 'uint256',
									name: 'nonce',
									type: 'uint256',
								},
							],
							internalType: 'struct BridgeAssist.FulfillTx',
							name: 'transaction',
							type: 'tuple',
						},
						{
							internalType: 'bytes[]',
							name: 'signatures',
							type: 'bytes[]',
						},
					],
					name: 'fulfill',
					outputs: [],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'string',
							name: '',
							type: 'string',
						},
						{
							internalType: 'string',
							name: '',
							type: 'string',
						},
						{
							internalType: 'uint256',
							name: '',
							type: 'uint256',
						},
					],
					name: 'fulfilledAt',
					outputs: [
						{
							internalType: 'uint256',
							name: '',
							type: 'uint256',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'getRelayers',
					outputs: [
						{
							internalType: 'address[]',
							name: '',
							type: 'address[]',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'bytes32',
							name: 'role',
							type: 'bytes32',
						},
					],
					name: 'getRoleAdmin',
					outputs: [
						{
							internalType: 'bytes32',
							name: '',
							type: 'bytes32',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'address',
							name: 'user',
							type: 'address',
						},
					],
					name: 'getUserTransactions',
					outputs: [
						{
							components: [
								{
									internalType: 'uint256',
									name: 'amount',
									type: 'uint256',
								},
								{
									internalType: 'uint256',
									name: 'timestamp',
									type: 'uint256',
								},
								{
									internalType: 'address',
									name: 'fromUser',
									type: 'address',
								},
								{
									internalType: 'string',
									name: 'toUser',
									type: 'string',
								},
								{
									internalType: 'string',
									name: 'fromChain',
									type: 'string',
								},
								{
									internalType: 'string',
									name: 'toChain',
									type: 'string',
								},
								{
									internalType: 'uint256',
									name: 'nonce',
									type: 'uint256',
								},
								{
									internalType: 'uint256',
									name: 'block',
									type: 'uint256',
								},
							],
							internalType: 'struct BridgeAssist.Transaction[]',
							name: '',
							type: 'tuple[]',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'address',
							name: 'user',
							type: 'address',
						},
					],
					name: 'getUserTransactionsAmount',
					outputs: [
						{
							internalType: 'uint256',
							name: '',
							type: 'uint256',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'address',
							name: 'user_',
							type: 'address',
						},
						{
							internalType: 'uint256',
							name: 'offset_',
							type: 'uint256',
						},
						{
							internalType: 'uint256',
							name: 'limit_',
							type: 'uint256',
						},
					],
					name: 'getUserTransactionsSlice',
					outputs: [
						{
							components: [
								{
									internalType: 'uint256',
									name: 'amount',
									type: 'uint256',
								},
								{
									internalType: 'uint256',
									name: 'timestamp',
									type: 'uint256',
								},
								{
									internalType: 'address',
									name: 'fromUser',
									type: 'address',
								},
								{
									internalType: 'string',
									name: 'toUser',
									type: 'string',
								},
								{
									internalType: 'string',
									name: 'fromChain',
									type: 'string',
								},
								{
									internalType: 'string',
									name: 'toChain',
									type: 'string',
								},
								{
									internalType: 'uint256',
									name: 'nonce',
									type: 'uint256',
								},
								{
									internalType: 'uint256',
									name: 'block',
									type: 'uint256',
								},
							],
							internalType: 'struct BridgeAssist.Transaction[]',
							name: 'transactions_',
							type: 'tuple[]',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'bytes32',
							name: 'role',
							type: 'bytes32',
						},
						{
							internalType: 'address',
							name: 'account',
							type: 'address',
						},
					],
					name: 'grantRole',
					outputs: [],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'bytes32',
							name: 'role',
							type: 'bytes32',
						},
						{
							internalType: 'address',
							name: 'account',
							type: 'address',
						},
					],
					name: 'hasRole',
					outputs: [
						{
							internalType: 'bool',
							name: '',
							type: 'bool',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'string',
							name: 'chain',
							type: 'string',
						},
					],
					name: 'isSupportedChain',
					outputs: [
						{
							internalType: 'bool',
							name: '',
							type: 'bool',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'limitPerSend',
					outputs: [
						{
							internalType: 'uint256',
							name: '',
							type: 'uint256',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'nonce',
					outputs: [
						{
							internalType: 'uint256',
							name: '',
							type: 'uint256',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'pause',
					outputs: [],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [],
					name: 'paused',
					outputs: [
						{
							internalType: 'bool',
							name: '',
							type: 'bool',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'relayerConsensusThreshold',
					outputs: [
						{
							internalType: 'uint256',
							name: '',
							type: 'uint256',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'uint256',
							name: '',
							type: 'uint256',
						},
					],
					name: 'relayers',
					outputs: [
						{
							internalType: 'address',
							name: '',
							type: 'address',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'relayersLength',
					outputs: [
						{
							internalType: 'uint256',
							name: '',
							type: 'uint256',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'string[]',
							name: 'chains',
							type: 'string[]',
						},
					],
					name: 'removeChains',
					outputs: [],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'bytes32',
							name: 'role',
							type: 'bytes32',
						},
						{
							internalType: 'address',
							name: 'account',
							type: 'address',
						},
					],
					name: 'renounceRole',
					outputs: [],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'bytes32',
							name: 'role',
							type: 'bytes32',
						},
						{
							internalType: 'address',
							name: 'account',
							type: 'address',
						},
					],
					name: 'revokeRole',
					outputs: [],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'uint256',
							name: 'amount',
							type: 'uint256',
						},
						{
							internalType: 'string',
							name: 'toUser',
							type: 'string',
						},
						{
							internalType: 'string',
							name: 'toChain',
							type: 'string',
						},
					],
					name: 'send',
					outputs: [],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'uint256',
							name: 'feeSend_',
							type: 'uint256',
						},
						{
							internalType: 'uint256',
							name: 'feeFulfill_',
							type: 'uint256',
						},
					],
					name: 'setFee',
					outputs: [],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'address',
							name: 'feeWallet_',
							type: 'address',
						},
					],
					name: 'setFeeWallet',
					outputs: [],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'uint256',
							name: 'limitPerSend_',
							type: 'uint256',
						},
					],
					name: 'setLimitPerSend',
					outputs: [],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'address[]',
							name: 'relayers_',
							type: 'address[]',
						},
						{
							internalType: 'uint256',
							name: 'relayerConsensusThreshold_',
							type: 'uint256',
						},
					],
					name: 'setRelayers',
					outputs: [],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [],
					name: 'supportedChainList',
					outputs: [
						{
							internalType: 'bytes32[]',
							name: '',
							type: 'bytes32[]',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'bytes4',
							name: 'interfaceId',
							type: 'bytes4',
						},
					],
					name: 'supportsInterface',
					outputs: [
						{
							internalType: 'bool',
							name: '',
							type: 'bool',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'address',
							name: '',
							type: 'address',
						},
						{
							internalType: 'uint256',
							name: '',
							type: 'uint256',
						},
					],
					name: 'transactions',
					outputs: [
						{
							internalType: 'uint256',
							name: 'amount',
							type: 'uint256',
						},
						{
							internalType: 'uint256',
							name: 'timestamp',
							type: 'uint256',
						},
						{
							internalType: 'address',
							name: 'fromUser',
							type: 'address',
						},
						{
							internalType: 'string',
							name: 'toUser',
							type: 'string',
						},
						{
							internalType: 'string',
							name: 'fromChain',
							type: 'string',
						},
						{
							internalType: 'string',
							name: 'toChain',
							type: 'string',
						},
						{
							internalType: 'uint256',
							name: 'nonce',
							type: 'uint256',
						},
						{
							internalType: 'uint256',
							name: 'block',
							type: 'uint256',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'unpause',
					outputs: [],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'contract IERC20',
							name: 'token',
							type: 'address',
						},
						{
							internalType: 'address',
							name: 'to',
							type: 'address',
						},
						{
							internalType: 'uint256',
							name: 'amount',
							type: 'uint256',
						},
					],
					name: 'withdraw',
					outputs: [],
					stateMutability: 'nonpayable',
					type: 'function',
				},
			],
		},
		token: {
			address: '0xe636f94a71eC52Cc61eF21787aE351AD832347B7',
			abi: [
				{
					inputs: [
						{
							internalType: 'string',
							name: '_name',
							type: 'string',
						},
						{
							internalType: 'string',
							name: '_symbol',
							type: 'string',
						},
						{
							internalType: 'uint256',
							name: '_totalSupply',
							type: 'uint256',
						},
						{
							internalType: 'address',
							name: '_owner',
							type: 'address',
						},
					],
					stateMutability: 'nonpayable',
					type: 'constructor',
				},
				{
					anonymous: false,
					inputs: [
						{
							indexed: true,
							internalType: 'address',
							name: 'owner',
							type: 'address',
						},
						{
							indexed: true,
							internalType: 'address',
							name: 'spender',
							type: 'address',
						},
						{
							indexed: false,
							internalType: 'uint256',
							name: 'value',
							type: 'uint256',
						},
					],
					name: 'Approval',
					type: 'event',
				},
				{
					anonymous: false,
					inputs: [
						{
							indexed: true,
							internalType: 'address',
							name: 'previousOwner',
							type: 'address',
						},
						{
							indexed: true,
							internalType: 'address',
							name: 'newOwner',
							type: 'address',
						},
					],
					name: 'OwnershipTransferred',
					type: 'event',
				},
				{
					anonymous: false,
					inputs: [
						{
							indexed: true,
							internalType: 'address',
							name: 'from',
							type: 'address',
						},
						{
							indexed: true,
							internalType: 'address',
							name: 'to',
							type: 'address',
						},
						{
							indexed: false,
							internalType: 'uint256',
							name: 'value',
							type: 'uint256',
						},
					],
					name: 'Transfer',
					type: 'event',
				},
				{
					inputs: [
						{
							internalType: 'address',
							name: 'owner',
							type: 'address',
						},
						{
							internalType: 'address',
							name: 'spender',
							type: 'address',
						},
					],
					name: 'allowance',
					outputs: [
						{
							internalType: 'uint256',
							name: '',
							type: 'uint256',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'address',
							name: 'spender',
							type: 'address',
						},
						{
							internalType: 'uint256',
							name: 'amount',
							type: 'uint256',
						},
					],
					name: 'approve',
					outputs: [
						{
							internalType: 'bool',
							name: '',
							type: 'bool',
						},
					],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'address',
							name: 'account',
							type: 'address',
						},
					],
					name: 'balanceOf',
					outputs: [
						{
							internalType: 'uint256',
							name: '',
							type: 'uint256',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'uint256',
							name: 'amount',
							type: 'uint256',
						},
					],
					name: 'burn',
					outputs: [],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'address',
							name: 'account',
							type: 'address',
						},
						{
							internalType: 'uint256',
							name: 'amount',
							type: 'uint256',
						},
					],
					name: 'burnFrom',
					outputs: [],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [],
					name: 'decimals',
					outputs: [
						{
							internalType: 'uint8',
							name: '',
							type: 'uint8',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'address',
							name: 'spender',
							type: 'address',
						},
						{
							internalType: 'uint256',
							name: 'subtractedValue',
							type: 'uint256',
						},
					],
					name: 'decreaseAllowance',
					outputs: [
						{
							internalType: 'bool',
							name: '',
							type: 'bool',
						},
					],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'address',
							name: 'spender',
							type: 'address',
						},
						{
							internalType: 'uint256',
							name: 'addedValue',
							type: 'uint256',
						},
					],
					name: 'increaseAllowance',
					outputs: [
						{
							internalType: 'bool',
							name: '',
							type: 'bool',
						},
					],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'address',
							name: '',
							type: 'address',
						},
					],
					name: 'locked',
					outputs: [
						{
							internalType: 'bool',
							name: '',
							type: 'bool',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'name',
					outputs: [
						{
							internalType: 'string',
							name: '',
							type: 'string',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'owner',
					outputs: [
						{
							internalType: 'address',
							name: '',
							type: 'address',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'renounceOwnership',
					outputs: [],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'address',
							name: 'user',
							type: 'address',
						},
						{
							internalType: 'bool',
							name: 'status',
							type: 'bool',
						},
					],
					name: 'setLocked',
					outputs: [],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [],
					name: 'symbol',
					outputs: [
						{
							internalType: 'string',
							name: '',
							type: 'string',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'totalSupply',
					outputs: [
						{
							internalType: 'uint256',
							name: '',
							type: 'uint256',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'address',
							name: 'to',
							type: 'address',
						},
						{
							internalType: 'uint256',
							name: 'amount',
							type: 'uint256',
						},
					],
					name: 'transfer',
					outputs: [
						{
							internalType: 'bool',
							name: '',
							type: 'bool',
						},
					],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'address',
							name: 'from',
							type: 'address',
						},
						{
							internalType: 'address',
							name: 'to',
							type: 'address',
						},
						{
							internalType: 'uint256',
							name: 'amount',
							type: 'uint256',
						},
					],
					name: 'transferFrom',
					outputs: [
						{
							internalType: 'bool',
							name: '',
							type: 'bool',
						},
					],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'address',
							name: 'newOwner',
							type: 'address',
						},
					],
					name: 'transferOwnership',
					outputs: [],
					stateMutability: 'nonpayable',
					type: 'function',
				},
			],
		},
	},
	bsc_mainnet: {
		chain_id: '56',
		assist: {
			address: '0xe636f94a71eC52Cc61eF21787aE351AD832347B7',
			abi: [
				{
					inputs: [
						{
							internalType: 'contract IERC20',
							name: 'token',
							type: 'address',
						},
						{
							internalType: 'uint256',
							name: 'limitPerSend_',
							type: 'uint256',
						},
						{
							internalType: 'address',
							name: 'feeWallet_',
							type: 'address',
						},
						{
							internalType: 'uint256',
							name: 'feeSend_',
							type: 'uint256',
						},
						{
							internalType: 'uint256',
							name: 'feeFulfill_',
							type: 'uint256',
						},
						{
							internalType: 'address',
							name: 'owner',
							type: 'address',
						},
						{
							internalType: 'address[]',
							name: 'relayers_',
							type: 'address[]',
						},
						{
							internalType: 'uint256',
							name: 'relayerConsensusThreshold_',
							type: 'uint256',
						},
					],
					stateMutability: 'nonpayable',
					type: 'constructor',
				},
				{
					anonymous: false,
					inputs: [
						{
							indexed: true,
							internalType: 'string',
							name: 'fromUser',
							type: 'string',
						},
						{
							indexed: true,
							internalType: 'address',
							name: 'toUser',
							type: 'address',
						},
						{
							indexed: false,
							internalType: 'string',
							name: 'fromChain',
							type: 'string',
						},
						{
							indexed: false,
							internalType: 'string',
							name: 'toChain',
							type: 'string',
						},
						{
							indexed: false,
							internalType: 'uint256',
							name: 'amount',
							type: 'uint256',
						},
						{
							indexed: false,
							internalType: 'uint256',
							name: 'exchangeRate',
							type: 'uint256',
						},
					],
					name: 'FulfilledTokens',
					type: 'event',
				},
				{
					anonymous: false,
					inputs: [
						{
							indexed: false,
							internalType: 'address',
							name: 'account',
							type: 'address',
						},
					],
					name: 'Paused',
					type: 'event',
				},
				{
					anonymous: false,
					inputs: [
						{
							indexed: true,
							internalType: 'bytes32',
							name: 'role',
							type: 'bytes32',
						},
						{
							indexed: true,
							internalType: 'bytes32',
							name: 'previousAdminRole',
							type: 'bytes32',
						},
						{
							indexed: true,
							internalType: 'bytes32',
							name: 'newAdminRole',
							type: 'bytes32',
						},
					],
					name: 'RoleAdminChanged',
					type: 'event',
				},
				{
					anonymous: false,
					inputs: [
						{
							indexed: true,
							internalType: 'bytes32',
							name: 'role',
							type: 'bytes32',
						},
						{
							indexed: true,
							internalType: 'address',
							name: 'account',
							type: 'address',
						},
						{
							indexed: true,
							internalType: 'address',
							name: 'sender',
							type: 'address',
						},
					],
					name: 'RoleGranted',
					type: 'event',
				},
				{
					anonymous: false,
					inputs: [
						{
							indexed: true,
							internalType: 'bytes32',
							name: 'role',
							type: 'bytes32',
						},
						{
							indexed: true,
							internalType: 'address',
							name: 'account',
							type: 'address',
						},
						{
							indexed: true,
							internalType: 'address',
							name: 'sender',
							type: 'address',
						},
					],
					name: 'RoleRevoked',
					type: 'event',
				},
				{
					anonymous: false,
					inputs: [
						{
							indexed: false,
							internalType: 'address',
							name: 'fromUser',
							type: 'address',
						},
						{
							indexed: true,
							internalType: 'string',
							name: 'toUser',
							type: 'string',
						},
						{
							indexed: false,
							internalType: 'string',
							name: 'fromChain',
							type: 'string',
						},
						{
							indexed: false,
							internalType: 'string',
							name: 'toChain',
							type: 'string',
						},
						{
							indexed: false,
							internalType: 'uint256',
							name: 'amount',
							type: 'uint256',
						},
						{
							indexed: false,
							internalType: 'uint256',
							name: 'exchangeRate',
							type: 'uint256',
						},
					],
					name: 'SentTokens',
					type: 'event',
				},
				{
					anonymous: false,
					inputs: [
						{
							indexed: false,
							internalType: 'address',
							name: 'account',
							type: 'address',
						},
					],
					name: 'Unpaused',
					type: 'event',
				},
				{
					inputs: [],
					name: 'CURRENT_CHAIN',
					outputs: [
						{
							internalType: 'string',
							name: '',
							type: 'string',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'CURRENT_CHAIN_B32',
					outputs: [
						{
							internalType: 'bytes32',
							name: '',
							type: 'bytes32',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'DEFAULT_ADMIN_ROLE',
					outputs: [
						{
							internalType: 'bytes32',
							name: '',
							type: 'bytes32',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'FEE_DENOMINATOR',
					outputs: [
						{
							internalType: 'uint256',
							name: '',
							type: 'uint256',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'FULFILL_TX_TYPEHASH',
					outputs: [
						{
							internalType: 'bytes32',
							name: '',
							type: 'bytes32',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'MANAGER_ROLE',
					outputs: [
						{
							internalType: 'bytes32',
							name: '',
							type: 'bytes32',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'MAX_RELAYERS',
					outputs: [
						{
							internalType: 'uint256',
							name: '',
							type: 'uint256',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'TOKEN',
					outputs: [
						{
							internalType: 'contract IERC20',
							name: '',
							type: 'address',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'string[]',
							name: 'chains',
							type: 'string[]',
						},
						{
							internalType: 'uint256[]',
							name: 'exchangeRatesFromPow',
							type: 'uint256[]',
						},
					],
					name: 'addChains',
					outputs: [],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'bytes32',
							name: '',
							type: 'bytes32',
						},
					],
					name: 'exchangeRateFrom',
					outputs: [
						{
							internalType: 'uint256',
							name: '',
							type: 'uint256',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'feeFulfill',
					outputs: [
						{
							internalType: 'uint256',
							name: '',
							type: 'uint256',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'feeSend',
					outputs: [
						{
							internalType: 'uint256',
							name: '',
							type: 'uint256',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'feeWallet',
					outputs: [
						{
							internalType: 'address',
							name: '',
							type: 'address',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [
						{
							components: [
								{
									internalType: 'uint256',
									name: 'amount',
									type: 'uint256',
								},
								{
									internalType: 'string',
									name: 'fromUser',
									type: 'string',
								},
								{
									internalType: 'address',
									name: 'toUser',
									type: 'address',
								},
								{
									internalType: 'string',
									name: 'fromChain',
									type: 'string',
								},
								{
									internalType: 'uint256',
									name: 'nonce',
									type: 'uint256',
								},
							],
							internalType: 'struct BridgeAssist.FulfillTx',
							name: 'transaction',
							type: 'tuple',
						},
						{
							internalType: 'bytes[]',
							name: 'signatures',
							type: 'bytes[]',
						},
					],
					name: 'fulfill',
					outputs: [],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'string',
							name: '',
							type: 'string',
						},
						{
							internalType: 'string',
							name: '',
							type: 'string',
						},
						{
							internalType: 'uint256',
							name: '',
							type: 'uint256',
						},
					],
					name: 'fulfilledAt',
					outputs: [
						{
							internalType: 'uint256',
							name: '',
							type: 'uint256',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'getRelayers',
					outputs: [
						{
							internalType: 'address[]',
							name: '',
							type: 'address[]',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'bytes32',
							name: 'role',
							type: 'bytes32',
						},
					],
					name: 'getRoleAdmin',
					outputs: [
						{
							internalType: 'bytes32',
							name: '',
							type: 'bytes32',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'address',
							name: 'user',
							type: 'address',
						},
					],
					name: 'getUserTransactions',
					outputs: [
						{
							components: [
								{
									internalType: 'uint256',
									name: 'amount',
									type: 'uint256',
								},
								{
									internalType: 'uint256',
									name: 'timestamp',
									type: 'uint256',
								},
								{
									internalType: 'address',
									name: 'fromUser',
									type: 'address',
								},
								{
									internalType: 'string',
									name: 'toUser',
									type: 'string',
								},
								{
									internalType: 'string',
									name: 'fromChain',
									type: 'string',
								},
								{
									internalType: 'string',
									name: 'toChain',
									type: 'string',
								},
								{
									internalType: 'uint256',
									name: 'nonce',
									type: 'uint256',
								},
								{
									internalType: 'uint256',
									name: 'block',
									type: 'uint256',
								},
							],
							internalType: 'struct BridgeAssist.Transaction[]',
							name: '',
							type: 'tuple[]',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'address',
							name: 'user',
							type: 'address',
						},
					],
					name: 'getUserTransactionsAmount',
					outputs: [
						{
							internalType: 'uint256',
							name: '',
							type: 'uint256',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'address',
							name: 'user_',
							type: 'address',
						},
						{
							internalType: 'uint256',
							name: 'offset_',
							type: 'uint256',
						},
						{
							internalType: 'uint256',
							name: 'limit_',
							type: 'uint256',
						},
					],
					name: 'getUserTransactionsSlice',
					outputs: [
						{
							components: [
								{
									internalType: 'uint256',
									name: 'amount',
									type: 'uint256',
								},
								{
									internalType: 'uint256',
									name: 'timestamp',
									type: 'uint256',
								},
								{
									internalType: 'address',
									name: 'fromUser',
									type: 'address',
								},
								{
									internalType: 'string',
									name: 'toUser',
									type: 'string',
								},
								{
									internalType: 'string',
									name: 'fromChain',
									type: 'string',
								},
								{
									internalType: 'string',
									name: 'toChain',
									type: 'string',
								},
								{
									internalType: 'uint256',
									name: 'nonce',
									type: 'uint256',
								},
								{
									internalType: 'uint256',
									name: 'block',
									type: 'uint256',
								},
							],
							internalType: 'struct BridgeAssist.Transaction[]',
							name: 'transactions_',
							type: 'tuple[]',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'bytes32',
							name: 'role',
							type: 'bytes32',
						},
						{
							internalType: 'address',
							name: 'account',
							type: 'address',
						},
					],
					name: 'grantRole',
					outputs: [],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'bytes32',
							name: 'role',
							type: 'bytes32',
						},
						{
							internalType: 'address',
							name: 'account',
							type: 'address',
						},
					],
					name: 'hasRole',
					outputs: [
						{
							internalType: 'bool',
							name: '',
							type: 'bool',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'string',
							name: 'chain',
							type: 'string',
						},
					],
					name: 'isSupportedChain',
					outputs: [
						{
							internalType: 'bool',
							name: '',
							type: 'bool',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'limitPerSend',
					outputs: [
						{
							internalType: 'uint256',
							name: '',
							type: 'uint256',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'nonce',
					outputs: [
						{
							internalType: 'uint256',
							name: '',
							type: 'uint256',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'pause',
					outputs: [],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [],
					name: 'paused',
					outputs: [
						{
							internalType: 'bool',
							name: '',
							type: 'bool',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'relayerConsensusThreshold',
					outputs: [
						{
							internalType: 'uint256',
							name: '',
							type: 'uint256',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'uint256',
							name: '',
							type: 'uint256',
						},
					],
					name: 'relayers',
					outputs: [
						{
							internalType: 'address',
							name: '',
							type: 'address',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'relayersLength',
					outputs: [
						{
							internalType: 'uint256',
							name: '',
							type: 'uint256',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'string[]',
							name: 'chains',
							type: 'string[]',
						},
					],
					name: 'removeChains',
					outputs: [],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'bytes32',
							name: 'role',
							type: 'bytes32',
						},
						{
							internalType: 'address',
							name: 'account',
							type: 'address',
						},
					],
					name: 'renounceRole',
					outputs: [],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'bytes32',
							name: 'role',
							type: 'bytes32',
						},
						{
							internalType: 'address',
							name: 'account',
							type: 'address',
						},
					],
					name: 'revokeRole',
					outputs: [],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'uint256',
							name: 'amount',
							type: 'uint256',
						},
						{
							internalType: 'string',
							name: 'toUser',
							type: 'string',
						},
						{
							internalType: 'string',
							name: 'toChain',
							type: 'string',
						},
					],
					name: 'send',
					outputs: [],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'uint256',
							name: 'feeSend_',
							type: 'uint256',
						},
						{
							internalType: 'uint256',
							name: 'feeFulfill_',
							type: 'uint256',
						},
					],
					name: 'setFee',
					outputs: [],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'address',
							name: 'feeWallet_',
							type: 'address',
						},
					],
					name: 'setFeeWallet',
					outputs: [],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'uint256',
							name: 'limitPerSend_',
							type: 'uint256',
						},
					],
					name: 'setLimitPerSend',
					outputs: [],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'address[]',
							name: 'relayers_',
							type: 'address[]',
						},
						{
							internalType: 'uint256',
							name: 'relayerConsensusThreshold_',
							type: 'uint256',
						},
					],
					name: 'setRelayers',
					outputs: [],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [],
					name: 'supportedChainList',
					outputs: [
						{
							internalType: 'bytes32[]',
							name: '',
							type: 'bytes32[]',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'bytes4',
							name: 'interfaceId',
							type: 'bytes4',
						},
					],
					name: 'supportsInterface',
					outputs: [
						{
							internalType: 'bool',
							name: '',
							type: 'bool',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'address',
							name: '',
							type: 'address',
						},
						{
							internalType: 'uint256',
							name: '',
							type: 'uint256',
						},
					],
					name: 'transactions',
					outputs: [
						{
							internalType: 'uint256',
							name: 'amount',
							type: 'uint256',
						},
						{
							internalType: 'uint256',
							name: 'timestamp',
							type: 'uint256',
						},
						{
							internalType: 'address',
							name: 'fromUser',
							type: 'address',
						},
						{
							internalType: 'string',
							name: 'toUser',
							type: 'string',
						},
						{
							internalType: 'string',
							name: 'fromChain',
							type: 'string',
						},
						{
							internalType: 'string',
							name: 'toChain',
							type: 'string',
						},
						{
							internalType: 'uint256',
							name: 'nonce',
							type: 'uint256',
						},
						{
							internalType: 'uint256',
							name: 'block',
							type: 'uint256',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'unpause',
					outputs: [],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'contract IERC20',
							name: 'token',
							type: 'address',
						},
						{
							internalType: 'address',
							name: 'to',
							type: 'address',
						},
						{
							internalType: 'uint256',
							name: 'amount',
							type: 'uint256',
						},
					],
					name: 'withdraw',
					outputs: [],
					stateMutability: 'nonpayable',
					type: 'function',
				},
			],
		},
		token: {
			address: '0x9521728bF66a867BC65A93Ece4a543D817871Eb7',
			abi: [
				{
					inputs: [
						{
							internalType: 'string',
							name: '_name',
							type: 'string',
						},
						{
							internalType: 'string',
							name: '_symbol',
							type: 'string',
						},
						{
							internalType: 'uint256',
							name: '_totalSupply',
							type: 'uint256',
						},
						{
							internalType: 'address',
							name: '_owner',
							type: 'address',
						},
					],
					stateMutability: 'nonpayable',
					type: 'constructor',
				},
				{
					anonymous: false,
					inputs: [
						{
							indexed: true,
							internalType: 'address',
							name: 'owner',
							type: 'address',
						},
						{
							indexed: true,
							internalType: 'address',
							name: 'spender',
							type: 'address',
						},
						{
							indexed: false,
							internalType: 'uint256',
							name: 'value',
							type: 'uint256',
						},
					],
					name: 'Approval',
					type: 'event',
				},
				{
					anonymous: false,
					inputs: [
						{
							indexed: true,
							internalType: 'address',
							name: 'previousOwner',
							type: 'address',
						},
						{
							indexed: true,
							internalType: 'address',
							name: 'newOwner',
							type: 'address',
						},
					],
					name: 'OwnershipTransferred',
					type: 'event',
				},
				{
					anonymous: false,
					inputs: [
						{
							indexed: true,
							internalType: 'address',
							name: 'from',
							type: 'address',
						},
						{
							indexed: true,
							internalType: 'address',
							name: 'to',
							type: 'address',
						},
						{
							indexed: false,
							internalType: 'uint256',
							name: 'value',
							type: 'uint256',
						},
					],
					name: 'Transfer',
					type: 'event',
				},
				{
					inputs: [
						{
							internalType: 'address',
							name: 'owner',
							type: 'address',
						},
						{
							internalType: 'address',
							name: 'spender',
							type: 'address',
						},
					],
					name: 'allowance',
					outputs: [
						{
							internalType: 'uint256',
							name: '',
							type: 'uint256',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'address',
							name: 'spender',
							type: 'address',
						},
						{
							internalType: 'uint256',
							name: 'amount',
							type: 'uint256',
						},
					],
					name: 'approve',
					outputs: [
						{
							internalType: 'bool',
							name: '',
							type: 'bool',
						},
					],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'address',
							name: 'account',
							type: 'address',
						},
					],
					name: 'balanceOf',
					outputs: [
						{
							internalType: 'uint256',
							name: '',
							type: 'uint256',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'uint256',
							name: 'amount',
							type: 'uint256',
						},
					],
					name: 'burn',
					outputs: [],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'address',
							name: 'account',
							type: 'address',
						},
						{
							internalType: 'uint256',
							name: 'amount',
							type: 'uint256',
						},
					],
					name: 'burnFrom',
					outputs: [],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [],
					name: 'decimals',
					outputs: [
						{
							internalType: 'uint8',
							name: '',
							type: 'uint8',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'address',
							name: 'spender',
							type: 'address',
						},
						{
							internalType: 'uint256',
							name: 'subtractedValue',
							type: 'uint256',
						},
					],
					name: 'decreaseAllowance',
					outputs: [
						{
							internalType: 'bool',
							name: '',
							type: 'bool',
						},
					],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'address',
							name: 'spender',
							type: 'address',
						},
						{
							internalType: 'uint256',
							name: 'addedValue',
							type: 'uint256',
						},
					],
					name: 'increaseAllowance',
					outputs: [
						{
							internalType: 'bool',
							name: '',
							type: 'bool',
						},
					],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'address',
							name: '',
							type: 'address',
						},
					],
					name: 'locked',
					outputs: [
						{
							internalType: 'bool',
							name: '',
							type: 'bool',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'name',
					outputs: [
						{
							internalType: 'string',
							name: '',
							type: 'string',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'owner',
					outputs: [
						{
							internalType: 'address',
							name: '',
							type: 'address',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'renounceOwnership',
					outputs: [],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'address',
							name: 'user',
							type: 'address',
						},
						{
							internalType: 'bool',
							name: 'status',
							type: 'bool',
						},
					],
					name: 'setLocked',
					outputs: [],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [],
					name: 'symbol',
					outputs: [
						{
							internalType: 'string',
							name: '',
							type: 'string',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [],
					name: 'totalSupply',
					outputs: [
						{
							internalType: 'uint256',
							name: '',
							type: 'uint256',
						},
					],
					stateMutability: 'view',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'address',
							name: 'to',
							type: 'address',
						},
						{
							internalType: 'uint256',
							name: 'amount',
							type: 'uint256',
						},
					],
					name: 'transfer',
					outputs: [
						{
							internalType: 'bool',
							name: '',
							type: 'bool',
						},
					],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'address',
							name: 'from',
							type: 'address',
						},
						{
							internalType: 'address',
							name: 'to',
							type: 'address',
						},
						{
							internalType: 'uint256',
							name: 'amount',
							type: 'uint256',
						},
					],
					name: 'transferFrom',
					outputs: [
						{
							internalType: 'bool',
							name: '',
							type: 'bool',
						},
					],
					stateMutability: 'nonpayable',
					type: 'function',
				},
				{
					inputs: [
						{
							internalType: 'address',
							name: 'newOwner',
							type: 'address',
						},
					],
					name: 'transferOwnership',
					outputs: [],
					stateMutability: 'nonpayable',
					type: 'function',
				},
			],
		},
	},
};

export default bridge;
