import classes from './index.module.less';

type SkeletonProps = {
	children?: any;
	isLoading?: boolean;
	variant?: 'text' | '';
	className?: string;
	width?: string | number;
	height?: string | number;
	borderRadius?: string;
	style?: object;
};

const Skeleton = ({
	children,
	isLoading = true,
	variant = '',
	className = '',
	width,
	height,
	borderRadius,
	style = {},
}: SkeletonProps) => {
	let borderRadiusProp: any = undefined;
	if (variant === 'text') {
		borderRadiusProp = 4;
	}
	if (borderRadius) {
		borderRadiusProp = borderRadius;
	}

	return (
		<>
			{isLoading && (
				<div
					className={`${classes.skeleton} ${className}`}
					style={{
						width: width,
						minHeight: height,
						height,
						borderRadius: borderRadiusProp,
						...style,
					}}
				/>
			)}
			<div className={isLoading ? 'd-none' : ''}>{children}</div>
		</>
	);
};

export default Skeleton;
