import BigNumberJs from 'bignumber.js';
import { BigNumber } from 'ethers';
import Web3 from 'web3';

import { useWeb3Context } from 'src/context/web3';
import { IContractDetailChests } from 'src/interface';
import web3config from 'src/utils/web3config';

import useXellarReducer from '../reducers/useXellarReducer';
import { getReturnValues } from '../xellar/useXellar';
import useWeb3Helper from './useWeb3Helper';

const useWeb3Marketplace = () => {
	const {
		canAccessWeb3,
		isXellar,
		xellarProvider,
		getXellarContract,
		provider,
		getContract,
		address,
	} = useWeb3Context();
	const { marketplaceAbi, marketplaceAddress, creoAddress } = web3config;
	const { web3Approval } = useWeb3Helper();

	const { xellarSendTransaction, setXellarItemData } = useXellarReducer();

	const convert18Int = (num: number): number => {
		return 10 ** 18 * num;
	};

	const web3ConvertCreoToUsd = async (price: number) => {
		if (!marketplaceAbi || !marketplaceAddress) {
			return {
				status: false,
				message: 'Marketplace abi or address not found',
			};
		}

		if (!canAccessWeb3) {
			return {
				status: false,
				message: 'Contract not found',
			};
		}

		try {
			const convertPrice = new BigNumberJs(convert18Int(Number(price)).toFixed()).toFixed();

			if (isXellar) {
				const contract = await getXellarContract(marketplaceAbi, marketplaceAddress);

				const convertionAmount = await contract.getExchangeRate(
					web3config.creoAddress,
					web3config.usdtAddress,
					convertPrice,
				);

				const priceUsd = convertionAmount / 10 ** 18;

				return { status: true, data: priceUsd };
			} else {
				const marketplaceContract = await getContract(marketplaceAbi, marketplaceAddress);

				const convertionAmount = await marketplaceContract.methods
					.getExchangeRate(web3config.creoAddress, web3config.usdtAddress, convertPrice)
					.call();
				// const priceUsd = convertBigNumber(convertionAmount, 4);
				const priceUsd = convertionAmount / 10 ** 18;

				return { status: true, data: priceUsd };
			}
		} catch (e) {
			console.log('err', e);
			return {
				status: false,
				message: (e as Error).message,
			};
		}
	};

	const web3ConvertUsdToCreo = async (price: number) => {
		if (!marketplaceAbi || !marketplaceAddress) {
			return {
				status: false,
				message: 'Marketplace abi or address not found',
			};
		}

		if (!canAccessWeb3) {
			return {
				status: false,
				message: 'Contract not found',
			};
		}

		try {
			const convertPrice = new BigNumberJs(convert18Int(Number(price))).toFixed();

			if (isXellar) {
				const marketplaceContract = await getXellarContract(marketplaceAbi, marketplaceAddress);

				const convertionAmount = await marketplaceContract.getExchangeRate(
					web3config.usdtAddress,
					web3config.creoAddress,
					convertPrice,
				);
				// const priceCreo = convertBigNumber(convertionAmount, 4);
				const priceCreo = convertionAmount / 10 ** 18;

				return { status: true, data: priceCreo };
			} else {
				const marketplaceContract = await getContract(marketplaceAbi, marketplaceAddress);

				const convertionAmount = await marketplaceContract.methods
					.getExchangeRate(web3config.usdtAddress, web3config.creoAddress, convertPrice)
					.call();
				// const priceCreo = convertBigNumber(convertionAmount, 4);
				const priceCreo = convertionAmount / 10 ** 18;

				return { status: true, data: priceCreo };
			}
		} catch (e) {
			console.log('err', e);
			return {
				status: false,
				message: (e as Error).message,
			};
		}
	};

	const web3GetItemByMarketplaceId = async (id: any) => {
		if (!canAccessWeb3 || !marketplaceAbi || !marketplaceAddress) {
			return false;
		}

		try {
			let itemData;

			if (isXellar) {
				const marketplaceContract = await getXellarContract(marketplaceAbi, marketplaceAddress);

				itemData = await marketplaceContract.getMarketItem(id);
			} else {
				const marketplaceContract = await getContract(marketplaceAbi, marketplaceAddress);

				itemData = await marketplaceContract.methods.getMarketItem(id).call();
			}

			return {
				itemId: itemData[0],
				nftAddress: itemData[1],
				tokenId: itemData[2],
				seller: itemData[4],
				owner: itemData[5],
				priceInUsd: Number(itemData[6] ?? 0),
				priceInToken: Number(itemData[7] ?? 0),
				status: itemData[8],
			};
		} catch (e) {
			console.log('err', e);
			return false;
		}
	};

	const web3BuyItem = async (
		itemId: any,
		price: number,
		contractDetail?: IContractDetailChests,
	) => {
		if (!marketplaceAbi || !marketplaceAddress) {
			return {
				status: false,
				message: 'Marketplace abi or address not found',
			};
		}

		if (!canAccessWeb3) {
			return {
				status: false,
				message: 'Contract not found',
			};
		}

		const errObj = {
			status: false,
		};

		try {
			const stringPrice = new BigNumberJs(price).toFixed();

			const web3 = isXellar ? Web3 : new Web3(provider);
			const priceItem = web3.utils.toWei(stringPrice, 'wei');

			await web3Approval(contractDetail?.contract_address ?? marketplaceAddress, priceItem);

			if (isXellar) {
				setXellarItemData({ funcName: 'BUY ITEM', price: priceItem });
				const buyResult: any = await xellarSendTransaction(
					contractDetail?.contract_address ?? marketplaceAddress,
					contractDetail?.contract_abi ?? marketplaceAbi,
					address,
					'buyItem',
					itemId,
				);

				if (!buyResult.status) {
					return {
						...errObj,
						message: 'User rejected',
						data: undefined,
					};
				}

				const returnValues = await getReturnValues(
					xellarProvider,
					contractDetail?.contract_address ?? marketplaceAddress,
					contractDetail?.contract_abi ?? marketplaceAbi,
					buyResult.data.txReceipt.hash,
					'MarketItemSold',
				);

				return {
					status: true,
					data: {
						...buyResult?.data,
						events: { MarketItemSold: { returnValues: returnValues } },
					},
				};
			} else {
				const marketContract = await getContract(
					contractDetail?.contract_abi ?? marketplaceAbi,
					contractDetail?.contract_address ?? marketplaceAddress,
				);

				const buyResult = await marketContract.methods.buyItem(itemId).send({ from: address });
				if (!buyResult) {
					return {
						...errObj,
						message: 'User rejected',
						data: undefined,
					};
				}

				return {
					status: true,
					data: buyResult,
				};
			}
		} catch (e) {
			console.log('error', e);
			return {
				...errObj,
				message: (e as Error).message,
			};
		}
	};

	const web3MakeOffer = async (itemId: any, price: number) => {
		if (!marketplaceAbi || !marketplaceAddress) {
			return {
				status: false,
				message: 'Marketplace abi or address not found',
			};
		}

		if (!canAccessWeb3) {
			return {
				status: false,
				message: 'Contract not found',
			};
		}

		const errObj = {
			status: false,
			message: undefined,
			data: undefined,
		};

		const stringPrice = new BigNumberJs(price).toFixed();

		const web3 = isXellar ? Web3 : new Web3(provider);
		const priceItem = web3.utils.toWei(stringPrice, 'wei');

		await web3Approval(marketplaceAddress, priceItem);

		try {
			if (isXellar) {
				setXellarItemData({ funcName: 'ADD OFFER', price: priceItem });
				const result: any = await xellarSendTransaction(
					marketplaceAddress,
					marketplaceAbi,
					address,
					'addOffer',
					itemId,
					priceItem,
				);

				if (!result.status) {
					return {
						...errObj,
						message: result?.message ?? 'User rejected',
					};
				}

				const returnValues = await getReturnValues(
					xellarProvider,
					marketplaceAddress,
					marketplaceAbi,
					result.data.txReceipt.hash,
					'AddOffer',
				);

				return {
					status: true,
					data: {
						...result?.data,
						events: { AddOffer: { returnValues: returnValues } },
					},
				};
			} else {
				const marketContract = await getContract(marketplaceAbi, marketplaceAddress);

				const result = await marketContract.methods
					.addOffer(itemId, priceItem)
					.send({ from: address });

				if (!result) {
					return {
						...errObj,
						message: result?.message ?? 'User rejected',
					};
				}

				return {
					status: true,
					data: result,
				};
			}
		} catch (e: any) {
			console.log('error', e);
			return {
				...errObj,
				message: e.message,
			};
		}
	};

	const web3CancelOffer = async (offerId: any) => {
		if (!marketplaceAbi || !marketplaceAddress) {
			return {
				status: false,
				message: 'Marketplace abi or address not found',
			};
		}

		if (!canAccessWeb3) {
			return {
				status: false,
				message: 'Contract not found',
			};
		}

		const errObj = {
			status: false,
			message: undefined,
			data: undefined,
		};

		try {
			if (isXellar) {
				setXellarItemData({ funcName: 'WITHDRAW OFFER' });
				const result: any = await xellarSendTransaction(
					marketplaceAddress,
					marketplaceAbi,
					address,
					'withdrawOffer',
					offerId,
				);

				if (!result.status) {
					return {
						...errObj,
						message: result?.message ?? 'User rejected',
					};
				}

				return result;
			} else {
				const marketContract = await getContract(marketplaceAbi, marketplaceAddress);

				const result = await marketContract.methods.withdrawOffer(offerId).send({ from: address });

				if (!result) {
					return {
						...errObj,
						message: result?.message ?? 'User rejected',
					};
				}

				return {
					status: true,
					data: result,
				};
			}
		} catch (e: any) {
			console.log('error', e);
			return {
				...errObj,
				message: e.message,
			};
		}
	};

	const web3AcceptOffer = async (offerId: any) => {
		if (!marketplaceAbi || !marketplaceAddress) {
			return {
				status: false,
				message: 'Marketplace abi or address not found',
			};
		}

		if (!canAccessWeb3) {
			return {
				status: false,
				message: 'Contract not found',
			};
		}

		const errObj = {
			status: false,
			message: undefined,
			data: undefined,
		};

		try {
			if (isXellar) {
				setXellarItemData({ funcName: 'APPROVE OFFER' });
				const result: any = await xellarSendTransaction(
					marketplaceAddress,
					marketplaceAbi,
					address,
					'approveOffer',
					offerId,
				);

				if (!result.status) {
					return {
						...errObj,
						message: result?.message ?? 'User rejected',
					};
				}

				return result;
			} else {
				const marketContract = await getContract(marketplaceAbi, marketplaceAddress);

				const result = await marketContract.methods.approveOffer(offerId).send({ from: address });

				if (!result) {
					return {
						...errObj,
						message: result?.message ?? 'User rejected',
					};
				}

				return {
					status: true,
					data: result,
				};
			}
		} catch (e: any) {
			console.log('error', e);
			return {
				...errObj,
				message: e.message,
			};
		}
	};

	const web3UnlistFromMarketplace = async (itemId: any) => {
		if (!marketplaceAbi || !marketplaceAddress) {
			return {
				status: false,
				message: 'Marketplace abi or address not found',
			};
		}

		if (!canAccessWeb3) {
			return {
				status: false,
				message: 'Contract not found',
			};
		}

		const errObj = {
			status: false,
		};

		try {
			if (isXellar) {
				setXellarItemData({ funcName: 'CANCEL MARKET ITEM' });
				const result: any = await xellarSendTransaction(
					marketplaceAddress,
					marketplaceAbi,
					address,
					'cancelMarketItem',
					itemId,
				);

				if (result?.status === false) {
					return {
						...errObj,
						message: result?.message ?? 'User rejected',
					};
				} else {
					return {
						status: true,
						data: result?.data,
					};
				}
			} else {
				const marketplaceContract = await getContract(marketplaceAbi, marketplaceAddress);

				const cancelResult = await marketplaceContract.methods
					.cancelMarketItem(itemId)
					.send({ from: address });

				return {
					status: true,
					data: cancelResult,
				};
			}
		} catch (e) {
			console.log('error', e);
			return {
				...errObj,
				message: (e as Error).message,
			};
		}
	};

	const web3ListToMarketplace = async (
		nftAddress: string,
		nftAbi: string,
		uri: string,
		priceUsd: number,
	) => {
		if (!marketplaceAbi || !marketplaceAddress) {
			return {
				status: false,
				message: 'Marketplace abi or address not found',
			};
		}

		if (!canAccessWeb3) {
			return {
				status: false,
				message: 'Contract not found',
			};
		}

		const errObj = {
			status: false,
			data: undefined,
		};

		try {
			const convertPrice = convert18Int(Number(priceUsd));
			const stringPrice = new BigNumberJs(convertPrice).toFixed();

			const web3 = isXellar ? Web3 : new Web3(provider);
			const priceItem = web3.utils.toWei(stringPrice, 'wei');

			if (isXellar) {
				// const nftContract = await getXellarContract(nftAbi, nftAddress);
				const marketplaceContract = await getXellarContract(marketplaceAbi, marketplaceAddress);

				const resAllowContracts = await marketplaceContract.allowedContracts(nftAddress);
				if (!resAllowContracts) {
					return {
						...errObj,
						message: resAllowContracts?.message ?? 'You cannot sell this item to marketplace',
						data: undefined,
					};
				}

				const approval: any = await xellarSendTransaction(
					nftAddress,
					nftAbi,
					address,
					'approve',
					marketplaceAddress,
					BigNumber.from(String(uri)),
				);

				if (approval?.status === false) {
					return {
						...errObj,
						message: approval?.message ?? 'User rejected',
					};
				}

				setXellarItemData({ funcName: 'SELL ITEM' });
				const result: any = await xellarSendTransaction(
					marketplaceAddress,
					marketplaceAbi,
					address,
					'sellItem',
					nftAddress,
					BigNumber.from(String(uri)),
					priceItem,
					creoAddress,
				);

				if (result?.status === false) {
					return {
						...errObj,
						message: result?.message ?? 'User rejected',
					};
				}

				const returnValues = await getReturnValues(
					xellarProvider,
					marketplaceAddress,
					marketplaceAbi,
					result.data.txReceipt.hash,
					'MarketItemCreated',
				);

				return {
					status: true,
					data: { ...result?.data, events: { MarketItemCreated: { returnValues: returnValues } } },
				};
			} else {
				const nftContract = await getContract(nftAbi, nftAddress);
				const marketplaceContract = await getContract(marketplaceAbi, marketplaceAddress);

				const resAllowContracts = await marketplaceContract.methods
					.allowedContracts(nftAddress)
					.call();

				if (!resAllowContracts) {
					return {
						...errObj,
						message: resAllowContracts?.message ?? 'You cannot sell this item to marketplace',
						data: undefined,
					};
				}

				const approval = await nftContract.methods
					.approve(marketplaceAddress, BigNumber.from(String(uri)))
					.send({ from: address });

				if (!approval) {
					return {
						...errObj,
						message: resAllowContracts?.message ?? 'User rejected',
						data: undefined,
					};
				}

				const result = await marketplaceContract.methods
					.sellItem(nftAddress, BigNumber.from(String(uri)), priceItem, creoAddress)
					.send({ from: address });

				if (!result) {
					return {
						...errObj,
						message: result?.message ?? 'User rejected',
					};
				}

				return {
					status: true,
					data: result,
				};
			}
		} catch (error: any) {
			return { ...errObj, message: error?.message };
		}
	};

	return {
		web3ConvertCreoToUsd,
		web3ConvertUsdToCreo,
		web3GetItemByMarketplaceId,
		web3BuyItem,
		web3MakeOffer,
		web3CancelOffer,
		web3AcceptOffer,
		web3UnlistFromMarketplace,
		web3ListToMarketplace,
	};
};

export default useWeb3Marketplace;
