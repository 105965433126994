import { useWeb3Context } from 'src/context/web3';
import web3config from 'src/utils/web3config';

import useXellarReducer from '../reducers/useXellarReducer';

const useWeb3Helper = () => {
	const { isXellar, getXellarContract, address, getContract, creoBalance }: any = useWeb3Context();

	const { xellarSendTransaction } = useXellarReducer();

	const web3Approval = async (targetAddress: string, priceItem: any) => {
		try {
			const { creoAbi, creoAddress } = web3config;

			if (isXellar) {
				const creoContract = await getXellarContract(creoAbi, creoAddress);

				const allowance = await creoContract.allowance(address, targetAddress);

				const balance = creoBalance * 10 ** 18;
				console.log({
					address,
					targetAddress,
					result: BigInt(allowance) < BigInt(priceItem),
					allowance: BigInt(allowance),
					priceItem: BigInt(priceItem),
					creoBalance: BigInt(balance),
				});
				// if (BigInt(allowance) < BigInt(priceItem)) {
				if (BigInt(allowance) < BigInt(balance)) {
					console.log('approval check');
					// approval check
					const approval: any = await xellarSendTransaction(
						creoAddress,
						creoAbi,
						address,
						'approve',
						targetAddress,
						// priceItem,
						balance.toString(),
					);
					console.log({ approval });

					if (approval?.status === false) {
						throw new Error('User does not approve');
					}
				}

				return true;
			} else {
				const creoContract = await getContract(creoAbi, creoAddress);

				const allowance = await creoContract.methods.allowance(address, targetAddress).call();

				if (BigInt(allowance) < BigInt(priceItem)) {
					// approval check
					const approval = await creoContract.methods
						.approve(targetAddress, priceItem)
						.send({ from: address });

					if (!approval) {
						throw new Error('User does not approve');
					}
				}

				return true;
			}
		} catch (error) {
			throw error;
		}
	};

	return {
		web3Approval,
	};
};

export default useWeb3Helper;
