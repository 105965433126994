const stakingabi = [
	{
		anonymous: false,
		inputs: [
			{ indexed: false, internalType: 'address', name: 'previousAdmin', type: 'address' },
			{ indexed: false, internalType: 'address', name: 'newAdmin', type: 'address' },
		],
		name: 'AdminChanged',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [{ indexed: true, internalType: 'address', name: 'beacon', type: 'address' }],
		name: 'BeaconUpgraded',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [{ indexed: false, internalType: 'uint8', name: 'version', type: 'uint8' }],
		name: 'Initialized',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{ indexed: true, internalType: 'address', name: 'previousOwner', type: 'address' },
			{ indexed: true, internalType: 'address', name: 'newOwner', type: 'address' },
		],
		name: 'OwnershipTransferred',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [{ indexed: false, internalType: 'address', name: 'account', type: 'address' }],
		name: 'Paused',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{ indexed: true, internalType: 'address', name: 'stakerAddress', type: 'address' },
			{ indexed: false, internalType: 'uint128', name: 'lockPeriodInDays', type: 'uint128' },
			{
				indexed: false,
				internalType: 'enum CreoEngineStaking.CompoundTypes',
				name: 'compoundType',
				type: 'uint8',
			},
			{ indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
			{ indexed: false, internalType: 'uint256', name: 'reward', type: 'uint256' },
			{ indexed: false, internalType: 'uint128', name: 'stakedAt', type: 'uint128' },
			{ indexed: false, internalType: 'uint128', name: 'endedAt', type: 'uint128' },
		],
		name: 'Staked',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [{ indexed: false, internalType: 'address', name: 'account', type: 'address' }],
		name: 'Unpaused',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{ indexed: true, internalType: 'address', name: 'stakerAddress', type: 'address' },
			{ indexed: false, internalType: 'uint128', name: 'lockPeriodInDays', type: 'uint128' },
			{
				indexed: false,
				internalType: 'enum CreoEngineStaking.CompoundTypes',
				name: 'compoundType',
				type: 'uint8',
			},
			{ indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
			{ indexed: false, internalType: 'uint256', name: 'reward', type: 'uint256' },
			{ indexed: false, internalType: 'uint256', name: 'prematurePenalty', type: 'uint256' },
			{ indexed: false, internalType: 'uint128', name: 'stakedAt', type: 'uint128' },
			{ indexed: false, internalType: 'uint128', name: 'endedAt', type: 'uint128' },
			{ indexed: false, internalType: 'uint128', name: 'unstakedAt', type: 'uint128' },
			{ indexed: false, internalType: 'bool', name: 'isPremature', type: 'bool' },
		],
		name: 'Unstaked',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [{ indexed: true, internalType: 'address', name: 'implementation', type: 'address' }],
		name: 'Upgraded',
		type: 'event',
	},
	{
		inputs: [],
		name: 'acceptProxyAdmin',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint128', name: '_lockPeriodInDays', type: 'uint128' },
			{ internalType: 'uint64', name: '_apy_d2', type: 'uint64' },
			{ internalType: 'uint64', name: '_feeInPercent_d2', type: 'uint64' },
		],
		name: 'addLockNumber',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'address', name: '_worker', type: 'address' }],
		name: 'addWorker',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: '_amount', type: 'uint256' },
			{ internalType: 'uint16', name: '_lockIndex', type: 'uint16' },
		],
		name: 'calculateReward',
		outputs: [{ internalType: 'uint256', name: 'reward', type: 'uint256' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint232', name: '_userStakedIndex', type: 'uint232' },
			{
				internalType: 'enum CreoEngineStaking.CompoundTypes',
				name: '_newCompoundType',
				type: 'uint8',
			},
		],
		name: 'changeCompoundType',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: '_oldWorker', type: 'address' },
			{ internalType: 'address', name: '_newWorker', type: 'address' },
		],
		name: 'changeWorker',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [],
		name: 'creoToken',
		outputs: [{ internalType: 'address', name: '', type: 'address' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'creoVToken',
		outputs: [{ internalType: 'address', name: '', type: 'address' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: '_token', type: 'address' },
			{ internalType: 'uint256', name: '_amount', type: 'uint256' },
			{ internalType: 'address', name: '_receiver', type: 'address' },
		],
		name: 'emergencyWithdraw',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: '_staker', type: 'address' },
			{ internalType: 'uint232', name: '_userStakedIndex', type: 'uint232' },
		],
		name: 'getStakedDetail',
		outputs: [
			{ internalType: 'uint128', name: 'lockPeriodInDays', type: 'uint128' },
			{ internalType: 'enum CreoEngineStaking.CompoundTypes', name: 'compoundType', type: 'uint8' },
			{ internalType: 'uint256', name: 'amount', type: 'uint256' },
			{ internalType: 'uint256', name: 'reward', type: 'uint256' },
			{ internalType: 'uint256', name: 'prematurePenalty', type: 'uint256' },
			{ internalType: 'uint128', name: 'stakedAt', type: 'uint128' },
			{ internalType: 'uint128', name: 'endedAt', type: 'uint128' },
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'address', name: '_staker', type: 'address' }],
		name: 'getStakerIndex',
		outputs: [{ internalType: 'uint232', name: '', type: 'uint232' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'address', name: '_staker', type: 'address' }],
		name: 'getTotalLockedTokens',
		outputs: [{ internalType: 'uint256', name: 'lockedTokens', type: 'uint256' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'uint128', name: '_beforeAt', type: 'uint128' }],
		name: 'getTotalStakedAmountBeforeDate',
		outputs: [{ internalType: 'uint256', name: 'totalStaked', type: 'uint256' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'address', name: '_staker', type: 'address' }],
		name: 'getTotalWithdrawableTokens',
		outputs: [{ internalType: 'uint256', name: 'withdrawableTokens', type: 'uint256' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'address', name: '_staker', type: 'address' }],
		name: 'getUserNextUnlock',
		outputs: [
			{ internalType: 'uint128', name: 'nextUnlockTime', type: 'uint128' },
			{ internalType: 'uint256', name: 'nextUnlockRewards', type: 'uint256' },
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: '_staker', type: 'address' },
			{ internalType: 'uint128', name: '_beforeAt', type: 'uint128' },
		],
		name: 'getUserStakedTokensBeforeDate',
		outputs: [{ internalType: 'uint256', name: 'lockedTokens', type: 'uint256' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'address', name: '', type: 'address' }],
		name: 'hasCreoV',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: '_creoToken', type: 'address' },
			{ internalType: 'address', name: '_creoVToken', type: 'address' },
			{ internalType: 'uint128[]', name: '_lockPeriodInDays', type: 'uint128[]' },
			{ internalType: 'uint64[]', name: '_apy_d2', type: 'uint64[]' },
			{ internalType: 'uint64[]', name: '_feeInPercent_d2', type: 'uint64[]' },
			{ internalType: 'address', name: '_savior', type: 'address' },
		],
		name: 'init',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'address', name: '', type: 'address' }],
		name: 'isTrustedForwarder',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'address', name: '', type: 'address' }],
		name: 'isWorker',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'lockNumber',
		outputs: [{ internalType: 'uint16', name: '', type: 'uint16' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'uint16', name: '_lockIndex', type: 'uint16' }],
		name: 'locked',
		outputs: [
			{ internalType: 'uint128', name: 'lockPeriodInDays', type: 'uint128' },
			{ internalType: 'uint64', name: 'apy_d2', type: 'uint64' },
			{ internalType: 'uint64', name: 'feeInPercent_d2', type: 'uint64' },
			{ internalType: 'uint256', name: 'creoStaked', type: 'uint256' },
			{ internalType: 'uint256', name: 'pendingReward', type: 'uint256' },
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'maxStaking',
		outputs: [{ internalType: 'uint128', name: '', type: 'uint128' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'minGetCreoV',
		outputs: [{ internalType: 'uint128', name: '', type: 'uint128' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'minStaking',
		outputs: [{ internalType: 'uint128', name: '', type: 'uint128' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'owner',
		outputs: [{ internalType: 'address', name: '', type: 'address' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'paused',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'proxiableUUID',
		outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'proxyAdmin',
		outputs: [{ internalType: 'address', name: '', type: 'address' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'address', name: '_worker', type: 'address' }],
		name: 'removeWorker',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [],
		name: 'savior',
		outputs: [{ internalType: 'address', name: '', type: 'address' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint16', name: '_lockIndex', type: 'uint16' },
			{ internalType: 'uint64', name: '_apy_d2', type: 'uint64' },
		],
		name: 'setAPY',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint128', name: '_minGetCreoV', type: 'uint128' },
			{ internalType: 'uint128', name: '_minStaking', type: 'uint128' },
			{ internalType: 'uint128', name: '_maxStaking', type: 'uint128' },
		],
		name: 'setMinMax',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint16', name: '_lockIndex', type: 'uint16' },
			{ internalType: 'uint64', name: '_feeInPercent_d2', type: 'uint64' },
		],
		name: 'setPenaltyFee',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint16', name: '_lockIndex', type: 'uint16' },
			{ internalType: 'uint128', name: '_newLockPeriodInDays', type: 'uint128' },
		],
		name: 'setPeriodInDays',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'address', name: '_savior', type: 'address' }],
		name: 'setSavior',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: '_amount', type: 'uint256' },
			{ internalType: 'uint16', name: '_lockIndex', type: 'uint16' },
			{
				internalType: 'enum CreoEngineStaking.CompoundTypes',
				name: '_compoundType',
				type: 'uint8',
			},
		],
		name: 'stake',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		name: 'staker',
		outputs: [{ internalType: 'address', name: '', type: 'address' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'address', name: '', type: 'address' }],
		name: 'stakerDetail',
		outputs: [
			{ internalType: 'uint256', name: 'stakedAmount', type: 'uint256' },
			{ internalType: 'uint256', name: 'stakerPendingReward', type: 'uint256' },
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'stakerLength',
		outputs: [{ internalType: 'uint256', name: 'length', type: 'uint256' }],
		stateMutability: 'view',
		type: 'function',
	},
	{ inputs: [], name: 'togglePause', outputs: [], stateMutability: 'nonpayable', type: 'function' },
	{
		inputs: [{ internalType: 'address', name: '_forwarder', type: 'address' }],
		name: 'toggleTrustedForwarder',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [],
		name: 'totalCreoStaked',
		outputs: [{ internalType: 'uint256', name: 'total', type: 'uint256' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'totalPendingReward',
		outputs: [{ internalType: 'uint256', name: 'total', type: 'uint256' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
		name: 'transferOwnership',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'address', name: '_newProxyAdmin', type: 'address' }],
		name: 'transferProxyAdmin',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint232', name: '_userStakedIndex', type: 'uint232' },
			{ internalType: 'uint256', name: '_amount', type: 'uint256' },
			{ internalType: 'address', name: '_staker', type: 'address' },
		],
		name: 'unstake',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'address', name: 'newImplementation', type: 'address' }],
		name: 'upgradeTo',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: 'newImplementation', type: 'address' },
			{ internalType: 'bytes', name: 'data', type: 'bytes' },
		],
		name: 'upgradeToAndCall',
		outputs: [],
		stateMutability: 'payable',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'address', name: '_staker', type: 'address' }],
		name: 'userStakedLength',
		outputs: [{ internalType: 'uint256', name: 'length', type: 'uint256' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'workerNumber',
		outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
		stateMutability: 'view',
		type: 'function',
	},
];
export default stakingabi;
