const marketplaceAbi = [
	{
		inputs: [
			{
				internalType: 'uint256',
				name: '_item_id',
				type: 'uint256',
			},
			{
				internalType: 'uint256',
				name: '_priceInToken',
				type: 'uint256',
			},
		],
		name: 'addOffer',
		outputs: [],
		stateMutability: 'payable',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_pancakeSwapRouter',
				type: 'address',
			},
			{
				internalType: 'address',
				name: '_globalToken',
				type: 'address',
			},
			{
				internalType: 'address',
				name: '_sellersubscriptionContract',
				type: 'address',
			},
			{
				internalType: 'address',
				name: '_buyersubscriptionContract',
				type: 'address',
			},
			{
				internalType: 'uint256',
				name: '_baseFee',
				type: 'uint256',
			},
			{
				internalType: 'uint256',
				name: '_buyerFee',
				type: 'uint256',
			},
			{
				internalType: 'uint256',
				name: '_sellerFee',
				type: 'uint256',
			},
		],
		stateMutability: 'nonpayable',
		type: 'constructor',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'uint256',
				name: 'itemId',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'offerId',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'address',
				name: 'actor',
				type: 'address',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'priceInToken',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'expiredAt',
				type: 'uint256',
			},
		],
		name: 'AddOffer',
		type: 'event',
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'nftContract',
				type: 'address',
			},
			{
				internalType: 'bool',
				name: 'isAllowed',
				type: 'bool',
			},
		],
		name: 'allowContracts',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: '_offer_id',
				type: 'uint256',
			},
		],
		name: 'approveOffer',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'uint256',
				name: 'itemId',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'offerId',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'address',
				name: 'actor',
				type: 'address',
			},
		],
		name: 'ApproveOffer',
		type: 'event',
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: 'itemId',
				type: 'uint256',
			},
		],
		name: 'buyItem',
		outputs: [],
		stateMutability: 'payable',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: 'itemId',
				type: 'uint256',
			},
		],
		name: 'cancelMarketItem',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'uint256',
				name: 'itemId',
				type: 'uint256',
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'nftContract',
				type: 'address',
			},
			{
				indexed: true,
				internalType: 'uint256',
				name: 'tokenId',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'bool',
				name: 'isVoluntarily',
				type: 'bool',
			},
		],
		name: 'MarketItemCanceled',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'uint256',
				name: 'itemId',
				type: 'uint256',
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'nftContract',
				type: 'address',
			},
			{
				indexed: true,
				internalType: 'uint256',
				name: 'tokenId',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'address',
				name: 'seller',
				type: 'address',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'price',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'priceInToken',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'address',
				name: 'currenyTokenAddress',
				type: 'address',
			},
		],
		name: 'MarketItemCreated',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'uint256',
				name: 'itemId',
				type: 'uint256',
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'nftContract',
				type: 'address',
			},
			{
				indexed: true,
				internalType: 'uint256',
				name: 'tokenId',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'address',
				name: 'buyer',
				type: 'address',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'price',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'priceInToken',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'buyPrice',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'sellerReceive',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'address',
				name: 'currenyTokenAddress',
				type: 'address',
			},
		],
		name: 'MarketItemSold',
		type: 'event',
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'nftContract',
				type: 'address',
			},
			{
				internalType: 'uint256',
				name: 'tokenId',
				type: 'uint256',
			},
			{
				internalType: 'uint256',
				name: 'price',
				type: 'uint256',
			},
			{
				internalType: 'address',
				name: 'tokenAddress',
				type: 'address',
			},
		],
		name: 'sellItem',
		outputs: [
			{
				components: [
					{
						internalType: 'uint256',
						name: 'itemId',
						type: 'uint256',
					},
					{
						internalType: 'address',
						name: 'nftContract',
						type: 'address',
					},
					{
						internalType: 'uint256',
						name: 'tokenId',
						type: 'uint256',
					},
					{
						internalType: 'address',
						name: 'currencyTokenAddress',
						type: 'address',
					},
					{
						internalType: 'address payable',
						name: 'seller',
						type: 'address',
					},
					{
						internalType: 'address payable',
						name: 'buyer',
						type: 'address',
					},
					{
						internalType: 'uint256',
						name: 'price',
						type: 'uint256',
					},
					{
						internalType: 'uint256',
						name: 'priceInToken',
						type: 'uint256',
					},
					{
						internalType: 'uint256',
						name: 'status',
						type: 'uint256',
					},
				],
				internalType: 'struct Marketplace.MarketItem',
				name: '',
				type: 'tuple',
			},
		],
		stateMutability: 'payable',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'user',
				type: 'address',
			},
			{
				internalType: 'bool',
				name: 'isAdminBool',
				type: 'bool',
			},
		],
		name: 'setAdmin',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'newContract',
				type: 'address',
			},
		],
		name: 'setBuyerSubscriptionContract',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: '_baseFee',
				type: 'uint256',
			},
			{
				internalType: 'uint256',
				name: '_buyerFee',
				type: 'uint256',
			},
			{
				internalType: 'uint256',
				name: '_sellerFee',
				type: 'uint256',
			},
		],
		name: 'setFee',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'address payable',
				name: 'newOwner',
				type: 'address',
			},
		],
		name: 'setOwner',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'newContract',
				type: 'address',
			},
		],
		name: 'setSellerSubscriptionContract',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256',
			},
			{
				internalType: 'address',
				name: 'currencyTokenAddress',
				type: 'address',
			},
		],
		name: 'withdrawFee',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: '_offer_id',
				type: 'uint256',
			},
		],
		name: 'withdrawOffer',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'uint256',
				name: 'itemId',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'offerId',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'address',
				name: 'actor',
				type: 'address',
			},
		],
		name: 'WithdrawOffer',
		type: 'event',
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address',
			},
		],
		name: 'allowedContracts',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'baseFee',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'buyerFee',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'buyerSubscriptionContract',
		outputs: [
			{
				internalType: 'contract ISubscription',
				name: '',
				type: 'address',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_token1',
				type: 'address',
			},
			{
				internalType: 'address',
				name: '_token2',
				type: 'address',
			},
			{
				internalType: 'uint256',
				name: '_amount',
				type: 'uint256',
			},
		],
		name: 'getExchangeRate',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'getItemIds',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'getItemsSold',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: 'itemId',
				type: 'uint256',
			},
		],
		name: 'getMarketItem',
		outputs: [
			{
				components: [
					{
						internalType: 'uint256',
						name: 'itemId',
						type: 'uint256',
					},
					{
						internalType: 'address',
						name: 'nftContract',
						type: 'address',
					},
					{
						internalType: 'uint256',
						name: 'tokenId',
						type: 'uint256',
					},
					{
						internalType: 'address',
						name: 'currencyTokenAddress',
						type: 'address',
					},
					{
						internalType: 'address payable',
						name: 'seller',
						type: 'address',
					},
					{
						internalType: 'address payable',
						name: 'buyer',
						type: 'address',
					},
					{
						internalType: 'uint256',
						name: 'price',
						type: 'uint256',
					},
					{
						internalType: 'uint256',
						name: 'priceInToken',
						type: 'uint256',
					},
					{
						internalType: 'uint256',
						name: 'status',
						type: 'uint256',
					},
				],
				internalType: 'struct Marketplace.MarketItem',
				name: '',
				type: 'tuple',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'getOfferIds',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'globalToken',
		outputs: [
			{
				internalType: 'contract IERC20',
				name: '',
				type: 'address',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256',
			},
		],
		name: 'idToMarketItem',
		outputs: [
			{
				internalType: 'uint256',
				name: 'itemId',
				type: 'uint256',
			},
			{
				internalType: 'address',
				name: 'nftContract',
				type: 'address',
			},
			{
				internalType: 'uint256',
				name: 'tokenId',
				type: 'uint256',
			},
			{
				internalType: 'address',
				name: 'currencyTokenAddress',
				type: 'address',
			},
			{
				internalType: 'address payable',
				name: 'seller',
				type: 'address',
			},
			{
				internalType: 'address payable',
				name: 'buyer',
				type: 'address',
			},
			{
				internalType: 'uint256',
				name: 'price',
				type: 'uint256',
			},
			{
				internalType: 'uint256',
				name: 'priceInToken',
				type: 'uint256',
			},
			{
				internalType: 'uint256',
				name: 'status',
				type: 'uint256',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256',
			},
		],
		name: 'idToOffers',
		outputs: [
			{
				internalType: 'uint256',
				name: 'itemId',
				type: 'uint256',
			},
			{
				internalType: 'address payable',
				name: 'actor',
				type: 'address',
			},
			{
				internalType: 'uint256',
				name: 'priceInToken',
				type: 'uint256',
			},
			{
				internalType: 'uint256',
				name: 'status',
				type: 'uint256',
			},
			{
				internalType: 'uint256',
				name: 'expiredAt',
				type: 'uint256',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'user',
				type: 'address',
			},
		],
		name: 'isAdmin',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256',
			},
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256',
			},
		],
		name: 'itemIdToOfferIds',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'owner',
		outputs: [
			{
				internalType: 'address payable',
				name: '',
				type: 'address',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'sellerFee',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'sellerSubscriptionContract',
		outputs: [
			{
				internalType: 'contract ISubscription',
				name: '',
				type: 'address',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address',
			},
		],
		name: 'storedFees',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
];

export default marketplaceAbi;
