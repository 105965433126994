import { useEffect, useState } from 'react';
// import Image from 'next/legacy/image';
import Image from 'next/image';

const defaultImg = '/images/no-image.svg';
const assetUrl = process.env.NEXT_PUBLIC_ASSETS_URL;
const patternValidUrl = new RegExp(/^(http(s?)):\/\//i);
const isAssetUrlValid = patternValidUrl.test(assetUrl || '');

export const customLoader = ({ src, width, quality }: any) => {
	if (process.env.IS_EXPORT === 'true') {
		return `${assetUrl}${src}?w=${width}&q=${quality || 100}`;
	}

	return `${src}?w=${width}&q=${quality || 100}`;
};

const getValidAsset = (url: string = '') => {
	if (!url) return '';

	const isValid = patternValidUrl.test(url);
	return isAssetUrlValid && !isValid ? `${assetUrl}${url}` : `${url}`;
};

const ImageC = (props: any) => {
	const { src, defaultSrc, objectFit, layout, ...attrs } = props;
	const srcAsset = getValidAsset(src);
	const defaultSrcAsset = getValidAsset(defaultSrc);
	const defaultImgAsset = getValidAsset(defaultImg);
	const selectedSrcImg = srcAsset || defaultSrcAsset || defaultImgAsset;
	const [imgSrc, setImgSrc] = useState(selectedSrcImg);

	const defaultSize = layout === 'fill' ? undefined : 120;
	// const defaultObjectFit = imgSrc === defaultImgAsset ? 'cover' : objectFit;

	useEffect(() => {
		if (!!src || !!defaultSrc) setImgSrc(selectedSrcImg);
	}, [src, defaultSrc]);

	const handleError = () => {
		if (imgSrc === srcAsset && !!defaultSrcAsset) {
			setImgSrc(defaultSrcAsset);
		} else {
			setImgSrc(defaultImgAsset);
		}
	};

	return (
		<Image
			loader={customLoader}
			width={defaultSize}
			height={defaultSize}
			src={imgSrc}
			// layout={layout}
			// objectFit={defaultObjectFit}
			onError={() => handleError()}
			alt="Image"
			{...attrs}
		/>
	);
};

export default ImageC;
