import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import classNames from 'classnames';

import ButtonC, { ButtonBorder } from 'src/components/Button';
import ImageC from 'src/components/Image';
import Skeleton from 'src/components/Skeleton';
import Spinner from 'src/components/Spinner';
import { useWeb3Context } from 'src/context/web3';
import useXellarReducer from 'src/hooks/reducers/useXellarReducer';
import { useGetPriceUsdFromCreo } from 'src/hooks/useInventoryDetail';
import convertBigNumber from 'src/utils/func/convert-bignumber';

import CardError from '../CardError';

import classes from './index.module.less';

interface Props {
	loading?: boolean;
	onReject: () => void;
	onConfirm: () => void;
	dataEstimateGas?: {
		loading?: boolean;
		error?: boolean;
		enoughBalance?: boolean;
		usd?: string | number;
		coin?: string | number;
		coin_code?: string;
	};
}

const CardTransaction = ({ loading = false, onReject, onConfirm, dataEstimateGas }: Props) => {
	const { xellarCurrentChain } = useWeb3Context();
	const { xellarTransactionData, xellarItemData } = useXellarReducer();

	const from = xellarTransactionData?.from ?? '';
	const to = xellarTransactionData?.to ?? '';

	const shortFrom = `${from.substring(0, 8)}...${from.substring(from.length - 4, from.length)}`;
	const shortTo = `${to.substring(0, 8)}...${to.substring(to.length - 4, to.length)}`;

	const loadingEstimateGas = dataEstimateGas?.loading;
	// const errorEstimateGas = !loadingEstimateGas && !dataEstimateGas?.coin;
	const gasFeeUsd = dataEstimateGas?.usd;
	const gasFee = dataEstimateGas?.coin;
	const coinCode = dataEstimateGas?.coin_code;

	const priceCreo = xellarItemData?.price ? convertBigNumber(xellarItemData?.price, 4) : undefined;
	const { data: dataPriceUsd } = useGetPriceUsdFromCreo(priceCreo ?? 0, priceCreo !== undefined);
	const priceUsd = parseFloat(dataPriceUsd?.toFixed(4));

	return (
		<Spinner spinning={loading}>
			<div className={classNames(classes.card, 'd-flex flex-column')}>
				<div className={classNames(classes.header, 'py-2 mt-1')}>
					<div className="d-flex align-items-center">
						<ImageC
							className={classes.image}
							width={15}
							height={15}
							src={xellarCurrentChain?.image_url}
						/>
						<span className="fs-12 fw-400 ml-2 opacity-80">{xellarCurrentChain?.name}</span>
					</div>
				</div>
				<div className={classes.header}>
					<div className="d-flex align-items-center">
						<ImageC className={classes.image} width={17} height={17} />
						<span className="fs-12 fw-400 ml-2 opacity-80">{shortFrom}</span>
					</div>
					<span className={classes.arrow}>
						<ImageC width={20} height={20} src="/icons/icon-arrow-right-round.svg" />
					</span>
					<div className="d-flex align-items-center justify-content-end">
						<ImageC className={classes.image} width={17} height={17} />
						<span className="fs-12 fw-400 ml-2 opacity-80">{shortTo}</span>
					</div>
				</div>
				<div className={classes.body}>
					<div className="d-flex flex-column mb-3">
						<span className="fs-12 fw-400 mb-2 opacity-80">{window.origin}</span>
						<span className={classNames('fs-12 fw-400', classes.funcName)}>
							<a>{shortTo}</a> <span className="opacity-80">: {xellarItemData?.funcName}</span>
						</span>
					</div>

					{!priceCreo ? (
						<div className="d-flex flex-column align-items-center w-fit-content mb-3">
							<ImageC
								className={classNames(classes.image, 'mb-1')}
								src="/images/gradient.png"
								width={48}
								height={48}
							/>
							<a className="fs-12 fw-700">$0.00</a>
						</div>
					) : (
						<div className="d-flex flex-column mb-3">
							<div className="d-flex align-items-center mb-1">
								<ImageC src="/icons/creo.svg" width={48} height={48} />
								<span className="fs-40 fw-500 lh-1 ml-2 opacity-80">{priceCreo} CREO</span>
							</div>
							<span className="fs-12 fw-700 opacity-80">${priceUsd !== 0 ? priceUsd : '0.00'}</span>
						</div>
					)}

					{/* <div className={classNames(classes.border, 'mb-3')}>
						<Row gutter={[7, 2]} className="w-100 m-0">
							<Col span={10}>
								<span className="d-flex fs-12 fw-700">Estimated changes</span>
							</Col>
							<Col span={14} className="text-right opacity-80">
								<div className="d-flex justify-content-end">
									<ImageC className={classes.image} width={17} height={17} />{' '}
									<span className="fs-12 fw-400 ml-2"></span>
								</div>
							</Col>
							<Col span={10}>
								<span className="d-flex fs-12 fw-700">You send</span>
							</Col>
							<Col span={14} className="d-flex flex-column text-right opacity-80">
								<span className="fs-12 fw-400"></span>
								<span className="fs-12 fw-400">$20.30</span>
							</Col>
						</Row>
						<Row gutter={[7, 2]} className="w-100 mt-3">
							<Col span={10}>
								<span className="d-flex fs-12 fw-700">You receive</span>
							</Col>
							<Col span={14} className="d-flex flex-column text-right opacity-80">
								<span className="fs-12 fw-400"></span>
								<span className="fs-12 fw-400">Not Available</span>
							</Col>
						</Row>
					</div> */}

					{dataEstimateGas?.error ? (
						<CardError>
							We were not able to estimate gas. There might be an error in the contract and this
							transaction may fail.
						</CardError>
					) : (
						<div className={classes.border}>
							<Row gutter={[7, 2]} className="w-100 opacity-80">
								<Col span={10}>
									<span className="d-flex fs-12 fw-400">Estimated fee</span>
								</Col>
								<Col span={14} className="d-flex flex-column text-right">
									{loadingEstimateGas ? (
										<>
											<Skeleton className="mb-1 ml-auto" variant="text" width={100} height={12} />
											<Skeleton className="ml-auto" variant="text" width={100} height={14} />
										</>
									) : (
										<>
											<span className="fs-12 fw-400">${gasFeeUsd}</span>
											<span className="fs-14 fw-700">
												{gasFee} {coinCode}
											</span>
										</>
									)}
								</Col>
								<Col span={10} className="mt-2">
									{/* <span className="d-flex fs-12 fw-400">Unknown processing time</span> */}
									<span className="fs-12 fw-400">Max Fee</span>
								</Col>
								<Col
									span={14}
									className="text-right d-flex align-items-end justify-content-end mt-2"
								>
									<div className="d-flex">
										{/* <span className="fs-12 fw-400">Max Fee: </span> */}
										{loadingEstimateGas ? (
											<Skeleton
												className="ml-1"
												variant="text"
												style={{ marginTop: 2 }}
												width={100}
												height={12}
											/>
										) : (
											<span className="fs-12 fw-700 ml-1">
												{gasFee} {coinCode}
											</span>
										)}
									</div>
								</Col>
							</Row>
						</div>
					)}
					{!dataEstimateGas?.enoughBalance && (
						<CardError className="mt-3">
							You do not have enough BNB in your account to pay for transaction fees on Binance
							Smart Chain network. Buy BNB or deposit from another account
						</CardError>
					)}
				</div>
				<div className={classes.footer}>
					<Row gutter={7} className="w-100">
						<Col span={12}>
							<ButtonBorder className="w-100 fs-16 fw-700" onClick={onReject}>
								Reject
							</ButtonBorder>
						</Col>
						<Col span={12}>
							<ButtonC
								className="w-100 fs-16 fw-700"
								onClick={onConfirm}
								disabled={loadingEstimateGas || !dataEstimateGas?.enoughBalance}
							>
								Continue
							</ButtonC>
						</Col>
					</Row>
				</div>
			</div>
		</Spinner>
	);
};

export default CardTransaction;
