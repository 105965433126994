import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IXellarPopupConfirmation {
	popupConfirmationOpen: boolean;
	popupConfirmationType: 'allowance' | 'transaction' | null;
	allowanceData: any;
	transactionData: any;
	result?: {
		status?: boolean | null;
		message?: string | null;
		data?: any;
	} | null;
	itemData?: {
		name?: string;
		funcName?: string;
		price?: string | number;
	} | null;
}

const initialState: IXellarPopupConfirmation = {
	popupConfirmationOpen: false,
	popupConfirmationType: null, // 'allowance' | 'transaction'
	allowanceData: null,
	transactionData: null,
	result: null,
	itemData: null,
};

export const xellarSlice = createSlice({
	name: 'xellarSlice',
	initialState: initialState,
	reducers: {
		setPopupConfirmationOpen(state: any, { payload }: PayloadAction<boolean>) {
			state.popupConfirmationOpen = payload;
		},
		setPopupConfirmationType(state: any, { payload }: PayloadAction<'allowance' | 'transaction'>) {
			state.popupConfirmationType = payload;
		},
		setAllowanceData(state: any, { payload }: PayloadAction<any>) {
			state.allowanceData = payload;
		},
		setTransactionData(state: any, { payload }: PayloadAction<any>) {
			state.transactionData = payload;
		},
		setItemData(state: any, { payload }: PayloadAction<any>) {
			state.itemData = payload;
		},
		updateStates(state: any, { payload }: PayloadAction<IXellarPopupConfirmation>) {
			Object.assign(state, { ...state, ...payload });
		},
		updateResult(state: any, { payload }: PayloadAction<IXellarPopupConfirmation['result']>) {
			Object.assign(state, { ...state, result: payload });
		},
		clearStates(state: any) {
			Object.assign(state, initialState);
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	setPopupConfirmationOpen,
	setPopupConfirmationType,
	setAllowanceData,
	setTransactionData,
	setItemData,
	updateStates,
	updateResult,
	clearStates,
} = xellarSlice.actions;

export default xellarSlice;
