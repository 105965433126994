import classNames from 'classnames';

import ImageC from 'src/components/Image';

import classes from './index.module.less';

const CardError = ({ className, children }: any) => {
	return (
		<div className={classNames(classes.card, className)}>
			<ImageC src="/icons/icon-error.svg" width={30} height={30} />
			{children}
		</div>
	);
};

export default CardError;
