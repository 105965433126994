const subscriptionabi = [
	{
		inputs: [
			{
				internalType: 'string',
				name: 'code',
				type: 'string',
			},
			{
				internalType: 'uint256',
				name: 'duration',
				type: 'uint256',
			},
			{
				internalType: 'uint256',
				name: 'price',
				type: 'uint256',
			},
			{
				internalType: 'address',
				name: 'tokenAddres',
				type: 'address',
			},
		],
		name: 'addProgram',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'string',
				name: 'code',
				type: 'string',
			},
		],
		name: 'buySubscription',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'string',
				name: 'code',
				type: 'string',
			},
		],
		name: 'cancelSubscription',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'user',
				type: 'address',
			},
			{
				internalType: 'bool',
				name: 'isAdminBool',
				type: 'bool',
			},
		],
		name: 'setAdmin',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_feeRecipient',
				type: 'address',
			},
		],
		name: 'setFeeRecipientAddress',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_pancakseSwapRouter',
				type: 'address',
			},
			{
				internalType: 'address',
				name: '_globalToken',
				type: 'address',
			},
			{
				internalType: 'address',
				name: '_feeRecipient',
				type: 'address',
			},
		],
		stateMutability: 'nonpayable',
		type: 'constructor',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'string',
				name: 'programCode',
				type: 'string',
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'user',
				type: 'address',
			},
		],
		name: 'CancelSubscription',
		type: 'event',
	},
	{
		inputs: [
			{
				internalType: 'string',
				name: 'code',
				type: 'string',
			},
			{
				internalType: 'uint256',
				name: 'duration',
				type: 'uint256',
			},
		],
		name: 'setProgramDuration',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'string',
				name: 'code',
				type: 'string',
			},
			{
				internalType: 'uint256',
				name: 'price',
				type: 'uint256',
			},
		],
		name: 'setProgramPrice',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'string',
				name: 'code',
				type: 'string',
			},
			{
				internalType: 'uint256',
				name: 'status',
				type: 'uint256',
			},
		],
		name: 'setProgramStatus',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'string',
				name: 'programCode',
				type: 'string',
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'user',
				type: 'address',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'priceInToken',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'address',
				name: 'tokenAddres',
				type: 'address',
			},
		],
		name: 'Subscribed',
		type: 'event',
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'newOwner',
				type: 'address',
			},
		],
		name: 'transferOwnership',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'userAddress',
				type: 'address',
			},
		],
		name: 'checkSubscription',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'feeRecipient',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_token1',
				type: 'address',
			},
			{
				internalType: 'address',
				name: '_token2',
				type: 'address',
			},
			{
				internalType: 'uint256',
				name: '_amount',
				type: 'uint256',
			},
		],
		name: 'getExchangeRate',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'string',
				name: 'code',
				type: 'string',
			},
		],
		name: 'getProgramByTokenAddress',
		outputs: [
			{
				components: [
					{
						internalType: 'string',
						name: 'code',
						type: 'string',
					},
					{
						internalType: 'uint256',
						name: 'duration',
						type: 'uint256',
					},
					{
						internalType: 'uint256',
						name: 'price',
						type: 'uint256',
					},
					{
						internalType: 'uint256',
						name: 'priceInToken',
						type: 'uint256',
					},
					{
						internalType: 'address',
						name: 'tokenAddress',
						type: 'address',
					},
					{
						internalType: 'uint256',
						name: 'status',
						type: 'uint256',
					},
				],
				internalType: 'struct CreoPlaySubscription.Program',
				name: '',
				type: 'tuple',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'getPrograms',
		outputs: [
			{
				components: [
					{
						internalType: 'string',
						name: 'code',
						type: 'string',
					},
					{
						internalType: 'uint256',
						name: 'duration',
						type: 'uint256',
					},
					{
						internalType: 'uint256',
						name: 'price',
						type: 'uint256',
					},
					{
						internalType: 'uint256',
						name: 'priceInToken',
						type: 'uint256',
					},
					{
						internalType: 'address',
						name: 'tokenAddress',
						type: 'address',
					},
					{
						internalType: 'uint256',
						name: 'status',
						type: 'uint256',
					},
				],
				internalType: 'struct CreoPlaySubscription.Program[]',
				name: '',
				type: 'tuple[]',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'globalToken',
		outputs: [
			{
				internalType: 'contract IERC20',
				name: '',
				type: 'address',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'user',
				type: 'address',
			},
		],
		name: 'isAdmin',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'owner',
		outputs: [
			{
				internalType: 'address payable',
				name: '',
				type: 'address',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'string',
				name: '',
				type: 'string',
			},
		],
		name: 'programs',
		outputs: [
			{
				internalType: 'string',
				name: 'code',
				type: 'string',
			},
			{
				internalType: 'uint256',
				name: 'duration',
				type: 'uint256',
			},
			{
				internalType: 'uint256',
				name: 'price',
				type: 'uint256',
			},
			{
				internalType: 'uint256',
				name: 'priceInToken',
				type: 'uint256',
			},
			{
				internalType: 'address',
				name: 'tokenAddress',
				type: 'address',
			},
			{
				internalType: 'uint256',
				name: 'status',
				type: 'uint256',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address',
			},
		],
		name: 'subscriptions',
		outputs: [
			{
				internalType: 'address',
				name: 'subscriber',
				type: 'address',
			},
			{
				internalType: 'uint256',
				name: 'endDate',
				type: 'uint256',
			},
			{
				internalType: 'bool',
				name: 'isActive',
				type: 'bool',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
];

export default subscriptionabi;
