import { useMutation, useQuery } from 'react-query';

import { useWeb3Context } from 'src/context/web3';
import { INftInventoryDetail, IOnListToMarket } from 'src/interface';
import convertBigNumber from 'src/utils/func/convert-bignumber';
import { notify } from 'src/utils/notify';

import useWeb3Inventory from './web3/useWeb3Inventory';
import useWeb3Marketplace from './web3/useWeb3Marketplace';
import useAPICaller from './useAPICaller';

export const getInventoryDetail = 'getInventoryDetail';

const getLastQueryValue = (url: string) => {
	if (!url) {
		return '';
	}
	const parts = url.split('/');
	return parts[parts.length - 1];
};

export const useGetInventoryDetail = (
	slug: string,
	itemType: string,
	uuid: string,
	enabled = true,
) => {
	const { fetchAPI } = useAPICaller();

	return useQuery(
		[getInventoryDetail, uuid],
		() => {
			return fetchAPI({ endpoint: `/games/slug/${slug}/item-type/${itemType}/uuid/${uuid}` });
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: enabled,
		},
	);
};

export const useGetNftDetail = (id: string, enabled = true) => {
	const { fetchAPI } = useAPICaller();

	return useQuery(
		['getNftDetail'],
		() => {
			return fetchAPI({ endpoint: `/inventories/nft/${id}` });
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false, enabled },
	);
};

export const useGetInGameItemDetail = (id: string, enabled = true) => {
	const { fetchAPI } = useAPICaller();

	return useQuery(
		['getInGameItemDetail'],
		() => {
			return fetchAPI({ endpoint: `/inventories/ingame-item/${id}` });
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled,
		},
	);
};

export const useGetNftId = (slug: string, itemType: string, nftId: string, enabled: boolean) => {
	const { fetchAPI } = useAPICaller();
	const encodeUrl = encodeURIComponent(nftId);

	return useQuery(
		[getInventoryDetail],
		() => {
			return fetchAPI({ endpoint: `/games/slug/${slug}/item-type/${itemType}/nftId/${encodeUrl}` });
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false, enabled: enabled },
	);
};

export const useGetNftOwner = (item: INftInventoryDetail, enabled: boolean) => {
	const { canAccessWeb3 } = useWeb3Context();
	const { web3GetNftOwner } = useWeb3Inventory();

	return useQuery(
		['queryKeyGetNftOwner', item],
		async () => {
			if (!canAccessWeb3) {
				return undefined;
			}

			const result: any = await web3GetNftOwner(
				item.contract_info.contract_abi,
				item.contract_info.contract_address,
				getLastQueryValue(item['nft_data.nft_id']),
			);
			if (!result) {
				return undefined;
			}

			return result;
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false, enabled: !!enabled },
	);
};

const onListToMarketPlace = async (props: IOnListToMarket) => {
	const { data, priceInUsd, listToMarketplace, fetchAPI } = props;

	const resWeb3 = await listToMarketplace(
		data.contract_info.contract_address,
		data.contract_info.contract_abi,
		getLastQueryValue(data['nft_data.nft_id']),
		priceInUsd,
	);

	if (!resWeb3.status) {
		throw new Error(resWeb3?.message);
	}

	const res = await fetchAPI({
		method: 'POST',
		endpoint: '/marketplaces/list',
		data: {
			nft_id: data['nft_data.nft_id'],
			price: convertBigNumber(priceInUsd * 10 ** 18, 4),
			web3MarketplaceId: resWeb3.data.events.MarketItemCreated.returnValues.itemId.toString(),
			priceInSecondaryCurrency:
				resWeb3.data.events.MarketItemCreated.returnValues.priceInToken.toString(),
			secondaryCurrencyCode:
				resWeb3.data.events.MarketItemCreated.returnValues.currenyTokenAddress.toString(),
		},
	});

	return res;
};

export const useListToMarketplace = () => {
	const { web3ListToMarketplace, web3ConvertCreoToUsd } = useWeb3Marketplace();
	const { fetchAPI } = useAPICaller();

	return useMutation(
		async (props: { data: INftInventoryDetail; price: number }) => {
			const res = await web3ConvertCreoToUsd(props.price);
			if (!res.status) {
				return res;
			}

			const priceInUsd = res.data ?? 0;

			return onListToMarketPlace({
				data: props.data,
				priceInUsd: priceInUsd,
				listToMarketplace: web3ListToMarketplace,
				fetchAPI,
			});
		},
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(err?.message, 'error');
			},
		},
	);
};

export const useUnlistFromMarketplace = () => {
	const { web3GetItemByMarketplaceId, web3UnlistFromMarketplace } = useWeb3Marketplace();
	const { fetchAPI } = useAPICaller();

	return useMutation(
		async (data: { web3_marketplace_id: number; nft_id: string }) => {
			const resItem = await web3GetItemByMarketplaceId(data.web3_marketplace_id);
			if (!resItem) {
				return resItem;
			}

			const itemId = resItem?.itemId;

			const response = await web3UnlistFromMarketplace(itemId);
			if (!response?.status) {
				return response;
			}

			return fetchAPI({
				method: 'POST',
				endpoint: '/marketplaces/unlist',
				data: {
					nft_id: data.nft_id,
				},
			});
		},
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(err?.message, 'error');
			},
		},
	);
};

interface ISendAndPullBack {
	itemTypeId: string;
	uuid: string;
	slug: string;
}

export const useSendNftToGame = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		async ({ itemTypeId, uuid, slug }: ISendAndPullBack) => {
			const res = await fetchAPI({
				method: 'PUT',
				endpoint: `/games/slug/${slug}/item/${uuid}/send`,
				data: { item_type_id: itemTypeId },
			});

			if (res.status !== 200) {
				throw new Error(res?.data?.message || 'Failed sending item to game');
			}

			return res;
		},
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(err?.message, 'error');
			},
		},
	);
};

export const useSendInGameItemToGame = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		async ({ id, quantity }: { id: string; quantity: number }) => {
			const res = await fetchAPI({
				endpoint: `/games/store-item-ownerships/${id}/claim`,
				method: 'PUT',
				data: { quantity },
			});

			if (res.status !== 200) {
				throw new Error(res?.data?.message || 'Failed sending item to game');
			}

			return res;
		},

		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(err?.message || 'Failed sending item to game!', 'error');
			},
		},
	);
};

export const usePullBackNft = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		({ itemTypeId, uuid, slug }: ISendAndPullBack) =>
			fetchAPI({
				method: 'PUT',
				endpoint: `/games/slug/${slug}/item/${uuid}/pull`,
				data: { item_type_id: itemTypeId },
			}),
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(err?.message, 'error');
			},
		},
	);
};

export const useGetPriceUsdFromCreo = (priceCreo: number, enabled = true) => {
	const { canAccessWeb3 } = useWeb3Context();
	const { web3ConvertCreoToUsd } = useWeb3Marketplace();

	return useQuery(
		['queryKeyGetPriceUsdFromCreo', priceCreo],
		async () => {
			if (!canAccessWeb3 || priceCreo === 0) {
				return 0;
			}

			const res: any = await web3ConvertCreoToUsd(priceCreo);
			if (res.status === false) {
				return 0;
			}

			return res.data;
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false, enabled: !!enabled },
	);
};

export const useGetPriceCreoFromUsd = (priceUsd: number, enabled = true) => {
	const { canAccessWeb3 } = useWeb3Context();
	const { web3ConvertUsdToCreo } = useWeb3Marketplace();

	return useQuery(
		['queryKeyGetPriceCreoFromUsd', priceUsd],
		async () => {
			if (!canAccessWeb3 || priceUsd === 0) {
				return 0;
			}

			const res: any = await web3ConvertUsdToCreo(priceUsd);

			if (res.status === false) {
				return 0;
			}

			return res.data;
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false, enabled: !!enabled },
	);
};

export const useGetListOffer = (nftUri: string, enabled = true) => {
	const { fetchAPI } = useAPICaller();

	return useQuery(
		['getListOffer', nftUri],
		() => {
			return fetchAPI({
				endpoint: '/marketplaces/find-offers-by?page=1&limit=1000',
				method: 'PUT',
				data: {
					nft_uri: nftUri,
				},
			});
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: !!enabled,
		},
	);
};
