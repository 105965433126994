import { createContext, ReactNode, useContext } from 'react';

import { useWeb3 } from 'src/hooks/web3/useWeb3Client';

const Web3Context = createContext<any>(null);

interface Props {
	children: ReactNode;
}

export const Web3ContextProvider = ({ children }: Props) => {
	const web3ProviderState = useWeb3();

	return <Web3Context.Provider value={web3ProviderState}>{children}</Web3Context.Provider>;
};

export function useWeb3Context() {
	return useContext(Web3Context);
}
