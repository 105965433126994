import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const authSlice = createSlice({
	name: 'authSlice',
	initialState: { user: null, modalAuthOpen: false },
	reducers: {
		setAuthUserData(state: any, { payload }: PayloadAction<{ user: any }>) {
			Object.assign(state, payload);
		},
		setModalAuthOpen(state: any, { payload }: PayloadAction<boolean>) {
			state.modalAuthOpen = payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setAuthUserData, setModalAuthOpen } = authSlice.actions;

export default authSlice;
