import message, { ArgsProps } from 'antd/lib/message';

export const notify = (
	content: string,
	type: 'success' | 'error' = 'success',
	options?: Partial<ArgsProps>,
) => {
	const key = Date.now();

	const config: ArgsProps = {
		key,
		content,
		className: 'notify',
		duration: 5,
		...options,
		onClick: () => message.destroy(key),
	};
	if (type === 'success') {
		return message.success(config);
	} else if (type === 'error') {
		return message.error(config);
	} else {
		return message.info(config);
	}
};
