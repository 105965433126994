import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import classNames from 'classnames';

import ButtonC, { ButtonBorder } from 'src/components/Button';
import Skeleton from 'src/components/Skeleton';
import Spinner from 'src/components/Spinner';
import useXellarReducer from 'src/hooks/reducers/useXellarReducer';

import CardError from '../CardError';

import classes from './index.module.less';

interface Props {
	loading?: boolean;
	onReject: () => void;
	onConfirm: () => void;
	dataEstimateGas?: {
		loading?: boolean;
		error?: boolean;
		enoughBalance?: boolean;
		usd?: string | number;
		coin?: string | number;
		coin_code?: string;
	};
}

const CardAllowance = ({ loading = false, onReject, onConfirm, dataEstimateGas }: Props) => {
	const { xellarItemData } = useXellarReducer();

	const loadingEstimateGas = dataEstimateGas?.loading;
	// const errorEstimateGas = !loadingEstimateGas && !dataEstimateGas?.coin;
	const gasFeeUsd = dataEstimateGas?.usd;
	const gasFee = dataEstimateGas?.coin;
	const coinCode = dataEstimateGas?.coin_code;

	return (
		<Spinner spinning={loading}>
			<div className={classNames(classes.card)}>
				<span className={classNames(classes.title, 'fs-20 fw-700 mb-4')}>
					Allow access to and transfer of your {xellarItemData?.name ?? 'token'}?
				</span>
				<span className="fs-12 fw-400 mb-3 opacity-80">
					{/* Lorem ipsum dolor sit amet consectetur. Donec netus orci dolor varius lectus. Iaculis sed. */}
				</span>
				<a className="fs-12 fw-700 mb-5">{/* Lorem ipsum dolor sit amet */}</a>
				{dataEstimateGas?.error ? (
					<CardError className="mt-3">
						We were not able to estimate gas. There might be an error in the contract and this
						transaction may fail.
					</CardError>
				) : (
					<Row gutter={[7, 2]} className="w-100 mt-3 mb-5 opacity-80">
						<Col span={10} className="text-left">
							<span className="fs-12 fw-400">Estimated fee</span>
						</Col>
						<Col span={14} className="d-flex flex-column text-right">
							{loadingEstimateGas ? (
								<>
									<Skeleton className="mb-1 ml-auto" variant="text" width={100} height={12} />
									<Skeleton className="ml-auto" variant="text" width={100} height={14} />
								</>
							) : (
								<>
									<span className="fs-12 fw-400">${gasFeeUsd}</span>
									<span className="fs-14 fw-700">
										{gasFee} {coinCode}
									</span>
								</>
							)}
						</Col>
						<Col span={10} className="text-left mt-2">
							{/* <span className="fs-12 fw-400">Unknown processing time</span> */}
							<span className="fs-12 fw-400">Max Fee: </span>
						</Col>
						<Col span={14} className="text-right d-flex align-items-end justify-content-end mt-2">
							<div className="d-flex">
								{/* <span className="fs-12 fw-400">Max Fee: </span> */}
								{loadingEstimateGas ? (
									<Skeleton
										className="ml-1"
										variant="text"
										style={{ marginTop: 2 }}
										width={100}
										height={12}
									/>
								) : (
									<span className="fs-12 fw-700 ml-1">
										{gasFee} {coinCode}
									</span>
								)}
							</div>
						</Col>
					</Row>
				)}
				{!dataEstimateGas?.enoughBalance && (
					<CardError className="mb-3">
						You do not have enough BNB in your account to pay for transaction fees on Binance Smart
						Chain network. Buy BNB or deposit from another account
					</CardError>
				)}
				<a className="fs-12 fw-700 mb-3 mt-auto">{/* Lorem ipsum dolor sit amet */}</a>
				<Row gutter={7} className="w-100">
					<Col span={12}>
						<ButtonBorder className="w-100 fs-16 fw-700" onClick={onReject}>
							Reject
						</ButtonBorder>
					</Col>
					<Col span={12}>
						<ButtonC
							className="w-100 fs-16 fw-700"
							onClick={onConfirm}
							disabled={loadingEstimateGas || !dataEstimateGas?.enoughBalance}
						>
							Continue
						</ButtonC>
					</Col>
				</Row>
			</div>
		</Spinner>
	);
};

export default CardAllowance;
