/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
import { nextReduxCookieMiddleware, wrapMakeStore } from 'next-redux-cookie-wrapper';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

import authSlice from './slices/authSlice';
import web3Slice from './slices/web3Slice';
import xellarSlice from './slices/xellarSlice';

export const selectAuth = (state: any) => state[authSlice.name];
export const selectWeb3 = (state: any) => state[web3Slice.name];
export const selectXellar = (state: any) => state[xellarSlice.name];

const combinedReducers = combineReducers({
	[authSlice.name]: authSlice.reducer,
	[web3Slice.name]: web3Slice.reducer,
	[xellarSlice.name]: xellarSlice.reducer,
});

const rootReducer = (state: any, action: any) => {
	if (action.type === HYDRATE) {
		const nextState = {
			...state,
			...action.payload,
		};
		return nextState;
	}

	return combinedReducers(state, action);
};

const makeStore = wrapMakeStore(() =>
	configureStore({
		reducer: rootReducer,
		middleware: (getDefaultMiddleware: any) =>
			getDefaultMiddleware({
				serializableCheck: {
					ignoredActions: [
						// 'web3slice/setWeb3Balance',
						// 'web3slice/setWeb3Nft',
						// 'web3slice/resetWeb3State',
					],
					ignoredPaths: [],
				},
			}).prepend(
				nextReduxCookieMiddleware({
					subtrees: [
						{
							subtree: 'authSlice.user',
							cookieName: 'creo.persist',
						},
					],
				}),
			),
	}),
);

export type AppStore = ReturnType<typeof makeStore>;

export const wrapper = createWrapper<AppStore>(makeStore, { debug: false });
