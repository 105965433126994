import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	clearStates,
	IXellarPopupConfirmation,
	setItemData,
	updateResult,
	updateStates,
} from 'src/redux/slices/xellarSlice';
import { selectXellar } from 'src/redux/store';

import { getXellarData } from '../xellar/useXellar';

const useXellarReducer = () => {
	const dispatch = useDispatch();
	const {
		popupConfirmationOpen,
		popupConfirmationType,
		allowanceData,
		transactionData,
		itemData,
		result,
	} = useSelector(selectXellar);

	const resultRef = useRef<any>(result);

	useEffect(() => {
		resultRef.current = result;
	}, [JSON.stringify(result)]);

	const setXellarItemData = (data: IXellarPopupConfirmation['itemData']) => {
		dispatch(setItemData(data));
	};

	const setXellarPopupConfirmationResult = async (data: IXellarPopupConfirmation['result']) => {
		dispatch(updateResult(data));
	};

	const clearXellarPopupConfirmationState = () => {
		dispatch(clearStates());
	};

	const xellarSendTransaction = async (
		contractAddress: string,
		contractAbi: string,
		walletAddress: string,
		funcName: string,
		...params: any[]
	) => {
		console.log('xellarSendTransaction');
		console.log({ contractAddress, contractAbi, walletAddress, funcName, ...params });

		const data = await getXellarData(
			contractAddress,
			contractAbi,
			walletAddress,
			funcName,
			...params,
		);

		dispatch(
			updateStates({
				popupConfirmationOpen: true,
				popupConfirmationType: funcName === 'approve' ? 'allowance' : 'transaction',
				allowanceData: funcName === 'approve' ? data : null,
				transactionData: funcName !== 'approve' ? data : null,
				result: null,
			}),
		);

		return new Promise((resolve) => {
			let checkInterval = setInterval(() => {
				if (resultRef.current?.status !== null && resultRef.current?.status !== undefined) {
					clearInterval(checkInterval);
					resolve(resultRef.current);
					clearXellarPopupConfirmationState();
				}
			}, 200);
		});
	};

	return {
		xellarPopupConfirmationOpen: popupConfirmationOpen,
		xellarPopupConfirmationType: popupConfirmationType,
		xellarAllowanceData: allowanceData,
		xellarTransactionData: transactionData,
		xellarItemData: itemData,
		setXellarItemData,
		setXellarPopupConfirmationResult,
		clearXellarPopupConfirmationState,
		xellarSendTransaction,
	};
};

export default useXellarReducer;
